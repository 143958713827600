import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from '../constants';
import {
  Newsletter,
  NewsletterCreationModel,
  NewsletterLocalizedResponse
} from '../interfaces/newsletter.interface';

@Injectable({
  providedIn: 'root'
})
export class NewsletterService {
  constructor(private http: HttpClient) {}

  getNewsletters() {
    return this.http.get<Newsletter[]>(Constants.NEWSLETTERS_URL, {
      responseType: 'json'
    });
  }

  getNewsletter(id: string) {
    return this.http.get<Newsletter>(`${Constants.NEWSLETTERS_URL}/${id}`, {
      responseType: 'json'
    });
  }

  saveNewsletter(newsletter: NewsletterCreationModel) {
    return this.http.post(Constants.NEWSLETTERS_URL, newsletter);
  }

  updateNewsletter(id: string, newsletter: NewsletterCreationModel) {
    return this.http.put(`${Constants.NEWSLETTERS_URL}/${id}`, newsletter);
  }

  deleteNewsletter(id: string) {
    return this.http.delete(`${Constants.NEWSLETTERS_URL}/${id}`);
  }
}
