import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { Localization } from 'src/app/shared/interfaces/failure.interface';
import { CoreService, CustomerService, Generation } from '../../../../shared/interfaces/service.interface';
import { CoreServiceClient, CustomerServiceClient } from '../../../../shared/services/services.service';
import { ServiceEditBaseDirective } from '../../service-edit-base.directive';

@Component({
  selector: 'app-customer-service-edit',
  templateUrl: './customer-service-edit.component.html',
  styleUrls: ['./customer-service-edit.component.scss']
})
export class CustomerServiceEditComponent extends ServiceEditBaseDirective<CustomerService> implements OnInit {
  private modIds = new UntypedFormArray([]);
  public accordionTabActive: number;

  public MOD_IDS_KEY = 'modIds';

  @Input() servicesProvider: () => Map<string, Map<Generation, CoreService[]>>;
  public brandServices: CoreService[];
  brands() {
    return Array.from(this.servicesProvider().keys());
  }

  constructor(private coreServiceClient: CoreServiceClient, private customerServiceClient: CustomerServiceClient) {
    super(coreServiceClient, customerServiceClient);
  }

  ngOnInit() {
    this.initCommonFields();
    super.ngOnInit();
    this.form.addControl(this.MOD_IDS_KEY, this.modIds);
    this.accordionTabActive = 0;
    this.updateServices();
    if (!this.creationMode) {
      this.initialObject.modIds.forEach((s) => this.selectService(s));
    }
  }

  onCheckChange(event) {
    if (event.target.checked) {
      this.selectService(event.target.value);
    } else {
      let i = 0;
      this.modIds.controls.forEach((ctrl: UntypedFormControl) => {
        if (ctrl.value === event.target.value) {
          this.modIds.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  selectService(modId: string) {
    this.modIds.push(new UntypedFormControl(modId));
  }

  handleBrandChange = () => {
    this.updateServices();
    this.updateValidators();
  };

  updateServices = () => {
    this.brandServices = this.servicesProvider()
      .get(this.form.get(this.BRAND_KEY).value)
      .get(this.form.get(this.GENERATION_KEY).value);
    this.modIds.clear();
  };

  onSupportedLanguageAdded(language: string) {
    const serviceDescriptions: Localization = this.form.get(this.SERVICE_DESCRIPTION_KEY).value;
    serviceDescriptions[language] = '';
  }

  onSupportedLanguageRemoved(language: string) {
    const serviceDescriptions: Localization = this.form.get(this.SERVICE_DESCRIPTION_KEY).value;
    delete serviceDescriptions[language];
  }

  setAccordionTabActive(tab: number) {
    if (this.accordionTabActive !== tab) {
      this.accordionTabActive = tab;
    } else {
      this.accordionTabActive = -1;
    }
  }
}
