import { Component, OnInit } from '@angular/core';
import { CoreServiceClient, CustomerServiceClient } from '../../shared/services/services.service';
import { ActivatedRoute, Params } from '@angular/router';
import { RolesService } from '../../shared/services/roles-service.service';
import { CoreService, CustomerService } from '../../shared/interfaces/service.interface';

@Component({
  selector: 'app-customer-service-details',
  templateUrl: './customer-service-details.component.html',
  styleUrls: ['./customer-service-details.component.scss']
})
export class CustomerServiceDetailsComponent implements OnInit {
  customerService: CustomerService;
  coreServices: CoreService[] = [];
  requestsDone = 0;

  constructor(
    private customerServiceClient: CustomerServiceClient,
    private coreServiceClient: CoreServiceClient,
    private activatedRoute: ActivatedRoute,
    public rolesService: RolesService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.customerServiceClient
        .getCustomerServiceByBrandAndModId(params.brand, params.modId)
        .subscribe((foundInstance: CustomerService) => {
          this.customerService = foundInstance;
          foundInstance.modIds.forEach((coreServiceModId) => {
            this.coreServiceClient
              .getServiceByBrandAndModId(foundInstance.brand, coreServiceModId)
              .subscribe((coreService: CoreService) => {
                this.coreServices.push(coreService);
                this.requestsDone++;
              });
          });
        });
    });
  }
}
