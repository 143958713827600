import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Failure } from 'src/app/shared/interfaces/failure.interface';
import { FailureType } from '../../../shared/interfaces/failure.interface';

@Component({
  selector: 'app-failure-item',
  templateUrl: './failure-item.component.html',
  styleUrls: ['./failure-item.component.scss']
})
export class FailureItemComponent implements OnInit {
  @Input() failure: Failure;

  @Input() editable = true;
  openAffirmationServiceModal = false;
  @Input() checked: boolean;
  public FailureType = FailureType;
  @Output() delete = new EventEmitter<Failure>();
  @Output() edit = new EventEmitter<Failure>();
  @Output() toggle = new EventEmitter<void>();

  ngOnInit(): void {}

  onEdit() {
    this.edit.emit(this.failure);
  }

  onAffirmationServiceModalClosed(confirmation: boolean) {
    if (confirmation) {
      this.delete.emit(this.failure);
    }

    this.openAffirmationServiceModal = false;
  }

  onDelete() {
    this.openAffirmationServiceModal = true;
  }

  onChecked() {
    this.toggle.emit();
  }
}
