import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-markdown-preview',
  templateUrl: './markdown-preview.component.html',
  styleUrls: ['./markdown-preview.component.scss']
})
export class MarkdownPreviewComponent implements OnInit, OnChanges {
  @Input() data: string | undefined;

  @Input() watchVariable: any;

  markdownViewOpened = false;

  constructor(public cd: ChangeDetectorRef) {}

  ngOnInit(): void {}

  // on watch change close markdown view
  ngOnChanges(changes: SimpleChanges) {
    if (changes.watchVariable && changes.watchVariable.previousValue !== changes.watchVariable.currentValue) {
      this.markdownViewOpened = false;
    }
  }
}
