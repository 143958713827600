import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { CoreService } from '../../shared/interfaces/service.interface';
import { env } from '../../environment';
import { RolesService } from '../../shared/services/roles-service.service';
import { CoreServiceClient } from '../../shared/services/services.service';

@Component({
  selector: 'app-service-detail',
  templateUrl: './service-detail.component.html',
  styleUrls: ['./service-detail.component.scss']
})
export class ServiceDetailComponent implements OnInit, OnDestroy {
  service: CoreService;
  dateFormat: string;

  private subscription: Subscription;

  constructor(
    private activatedRoute: ActivatedRoute,
    private coreServiceClient: CoreServiceClient,
    public rolesService: RolesService
  ) {
    this.dateFormat = env.dateFormat;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngOnInit() {
    this.subscription = this.activatedRoute.params.subscribe((params: Params) => {
      this.coreServiceClient
        .getServiceByBrandAndModId(params.brand, params.modId)
        .subscribe((foundInstance: CoreService) => {
          this.service = foundInstance;
        });
    });
  }
}
