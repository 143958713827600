import { Component, OnInit } from '@angular/core';
import { HistoryService } from '../../shared/services/history.service';
import { History } from '../../shared/interfaces/history.interface';
import { env } from '../../environment';
import { RolesService } from '../../shared/services/roles-service.service';
import { Role } from '../../shared/constants';
import { ItemsBaseDirective } from '../../components/services/items-base.directive';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent extends ItemsBaseDirective<History> implements OnInit {
  dateTimeFormat: string;
  readonly ADMIN_ROLE: string = Role.ADMIN;
  historiesToDisplay = '10';

  constructor(
    private historyService: HistoryService,
    public rolesService: RolesService,
    public translateService: TranslateService,
    router: Router,
    route: ActivatedRoute
  ) {
    super(historyService, rolesService, router, route);
    this.dateTimeFormat = env.dateTimeFormat;
  }

  historyEvents: History[];

  ngOnInit(): void {
    this.historyService.getItems().subscribe((values) => {
      this.historyEvents = values;
      this.allItems = values;

      if (!this.filters && !this.filters.length) {
        this.setVisibilityFilter((s: History[]) => s.slice(0, 10));
      }
      this.refreshItems();
    });
  }

  fieldsToFilterOn(): string[] {
    return ['message', 'user', 'createDate', 'actionType'];
  }

  itemConstructor(): History {
    return {} as History;
  }

  updateNumberOfItemsToDisplay(displayNumber: number) {
    if (displayNumber === -1) {
      this.translateService.get('HISTORY.SHOW_ALL').subscribe((result) => (this.historiesToDisplay = result));
      this.loadRemainingData();
    } else {
      this.historiesToDisplay = displayNumber.toString();
      this.filters.length = 0;
      this.setVisibilityFilter((s: History[]) => s.slice(0, displayNumber));
    }
  }

  changeFilter() {
    this.translateService.get('HISTORY.SHOW_ALL').subscribe((result) => (this.historiesToDisplay = result));
    this.onFilterUpdated();
  }
}
