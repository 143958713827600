<div>
  <div class="row row--container">
    <button
      *ngIf="hasAnyMaster()"
      id="addServiceButton"
      class="btn btn--fab btn--primary add-button"
      type="submit"
      (click)="createNewItem()"
    >
      <i class="icon i-close"></i>
    </button>

    <app-filter-component
      (addPreparedFilter)="addPreparedFilter($event)"
      [brands]="rolesService.getBrandsWhereMaster()"
      [filters]="filters"
      (filterUpdated)="onFilterUpdated()"
    >
    </app-filter-component>
  </div>

  <div class="row row--container mar-bottom-large">
    <app-service-item
      *ngFor="let service of itemsToDisplay"
      [service]="service"
      [editable]="isMaster(service)"
      class="col lg-4 md-6 sm-12 flex flex--equal-size"
      name="serviceWindow"
      (edit)="onEdit($event)"
      (delete)="onDelete($event)"
      (showDetails)="navigateToCustomerServiceDetails(service)"
    >
    </app-service-item>
  </div>

  <div class="center mar-bottom-large">
    <p class="center-div">
      {{ 'CREATE_SERVICE_MODAL.NUM_OF_RESULTS' | translate: { x: itemsToDisplay?.length, y: allItems?.length } }}
    </p>
    <div class="center-div">
      <progress value="{{ loadedPercentage || 0 }}" max="100"></progress>
    </div>
    <div class="center-div">
      <button
        class="btn btn--tertiary show-all"
        (click)="loadRemainingData()"
        [disabled]="itemsToDisplay?.length === allItems?.length"
      >
        {{ 'CREATE_SERVICE_MODAL.SHOW_ALL' | translate }}
      </button>
    </div>
  </div>
</div>

<app-customer-service-edit
  *ngIf="editedItem !== null && editedItem !== undefined"
  [initialObject]="editedItem"
  (closed)="editWindowClosed()"
  (oncreate)="itemCreated($event)"
  (onupdate)="itemUpdated($event)"
  [servicesProvider]="servicesProvider"
>
</app-customer-service-edit>
