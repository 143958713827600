import { Component, OnInit } from '@angular/core';
import { Constants } from '../../shared/constants';
import { RolesService } from '../../shared/services/roles-service.service';
import { ActivatedRoute, Router } from '@angular/router';

enum ServiceView {
  CORE = 'core',
  CUSTOMER = 'customer',
  CUSTOMIZED = 'customized'
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  readonly ALL_ROLES: string[] = Constants.ALL_ROLES;
  ServiceView = ServiceView;

  activeTab = ServiceView.CORE;
  serviceNumber = 0;

  constructor(public rolesService: RolesService, private router: Router, private route: ActivatedRoute) {
    this.initView = this.initView.bind(this);
    this.initView(this.route.snapshot.queryParams.view);
    this.route.queryParams.subscribe((p) => this.initView(p.view));
  }

  private initView(serviceView: ServiceView) {
    this.activeTab = serviceView || ServiceView.CORE;
  }

  ngOnInit(): void {}

  onServiceNumberChanged(newNumber: number) {
    this.serviceNumber = newNumber;
  }

  async onTabChange(newView: ServiceView) {
    this.activeTab = newView;

    await this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParams: { view: newView },
      queryParamsHandling: 'merge',
      replaceUrl: true
    });
  }

  isActive(serviceView: ServiceView) {
    return this.activeTab === serviceView;
  }
}
