<div class="modal">
  <div class="modal__wrapper">
    <h1 class="modal__title">
      {{ 'CREATE_SERVICE_MODAL.MODAL_TITLE_' + (creationMode ? 'CREATE' : 'EDIT') | translate }}
    </h1>
    <div class="modal__close" (click)="close()"></div>
    <div class="modal__content">
      <form (ngSubmit)="onSubmit()" [formGroup]="form">
        <ul id="serviceContent" name="serviceContent" class="accordion">
          <li id="basicInfoTab" name="basicInfoTab" [ngClass]="{ 'is-open': accordionTabActive === 0 }">
            <div class="accordion__title" (click)="setAccordionTabActive(0)">
              {{ 'CREATE_SERVICE_MODAL.BASIC_INFO' | translate }}
            </div>
            <div class="accordion__content">
              <div class="row">
                <div class="col lg-7 md-7" [ngClass]="{ 'wrapper wrapper--input is-inactive': !creationMode }">
                  <label for="name">
                    {{ 'CREATE_SERVICE_MODAL.NAME' | translate }}
                    <input class="form-control" formControlName="{{ NAME_KEY }}" type="text" id="name" name="name" />
                  </label>
                </div>

                <div class="col lg-5 md-5" [ngClass]="{ 'wrapper wrapper--input is-inactive': !creationMode }">
                  <label for="modId">
                    {{ 'CREATE_SERVICE_MODAL.MOD_ID' | translate }}
                    <input
                      class="form-control"
                      formControlName="{{ MOD_ID_KEY }}"
                      type="text"
                      id="modId"
                      name="modId"
                    />
                  </label>
                </div>

                <app-form-error [visible]="form.controls[NAME_KEY].hasError('required') && submitted">
                  {{ 'CREATE_SERVICE_MODAL.FIELD_SERVICE_NAME_REQUIRED' | translate }}
                </app-form-error>
                <app-form-error [visible]="form.controls[MOD_ID_KEY].hasError('required') && submitted">
                  {{ 'CREATE_SERVICE_MODAL.FIELD_MOD_ID_REQUIRED' | translate }}
                </app-form-error>

                <app-form-error [visible]="form.controls[NAME_KEY].hasError('included') && submitted">
                  {{
                    'CREATE_SERVICE_MODAL.SERVICE_NAME_ALREADY_EXISTS'
                      | translate: { x: form.get(this.NAME_KEY).value, y: form.get(this.BRAND_KEY).value }
                  }}
                </app-form-error>
                <app-form-error [visible]="form.controls[MOD_ID_KEY].hasError('included') && submitted">
                  {{
                    'CREATE_SERVICE_MODAL.SERVICE_MODID_ALREADY_EXISTS'
                      | translate: { x: form.get(this.MOD_ID_KEY).value, y: form.get(this.BRAND_KEY).value }
                  }}
                </app-form-error>
              </div>

              <div class="row">
                <div class="col lg-4 md-4" [ngClass]="{ 'wrapper wrapper--input is-inactive': !creationMode }">
                  <label for="brand">
                    {{ 'CREATE_SERVICE_MODAL.BRAND' | translate }}
                    <div class="wrapper wrapper--select">
                      <select id="brand" name="brand" formControlName="{{ BRAND_KEY }}" (change)="handleBrandChange()">
                        <option *ngFor="let brand of brands()" [value]="brand">
                          {{ brand }}
                        </option>
                      </select>
                    </div>
                  </label>
                </div>

                <div class="col lg-4 md-4">
                  <label for="date">
                    {{ 'CREATE_SERVICE_MODAL.DATE' | translate }}
                    <input class="form-control" formControlName="{{ DATE_KEY }}" id="date" type="date" name="date" />
                  </label>
                </div>
              </div>

              <div class="row">
                <div class="col lg-12 xl-7">
                  <label for="supportedLanguages">
                    {{ 'CREATE_SERVICE_MODAL.SUPPORTED_LANGUAGES' | translate }}
                    <div class="wrapper">
                      <app-multi-value-input
                        formControlName="{{ SUPPORTED_LANGUAGES_KEY }}"
                        (itemAdded)="onSupportedLanguageAdded($event)"
                        (itemRemoved)="onSupportedLanguageRemoved($event)"
                      >
                      </app-multi-value-input>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </li>

          <li id="serviceFilters" name="serviceFilters" [ngClass]="{ 'is-open': accordionTabActive === 1 }">
            <div class="accordion__title" (click)="setAccordionTabActive(1)">
              {{ 'CREATE_SERVICE_MODAL.SERVICE_FILTERS' | translate }}
            </div>
            <div class="accordion__content">
              <div class="filter__operator">
                <li>
                  <select name="" id="" (change)="this.changeFilterOperator($event)">
                    <option value="and">AND</option>
                    <option value="or">OR</option>
                  </select>
                </li>
              </div>
              <ul *ngFor="let filter of this.formFilters.nodes; index as i" class="single-filter">
                <li>
                  <input
                    type="text"
                    placeholder="Mlaas path"
                    (change)="handleInputChange($event, filter.id, 'mlaasPath')"
                    value="{{ filter.mlaasPath }}"
                  />
                </li>
                <li>
                  <div class="wrapper wrapper--select">
                    <select (change)="handleSelectChange($event, filter.id)">
                      <option
                        *ngFor="let operator of stringOperators"
                        value="{{ operator.value }}"
                        [selected]="filter.type === operator.value"
                      >
                        {{ operator.displayName }}
                      </option>
                    </select>
                  </div>
                </li>
                <li>
                  <input
                    type="text"
                    placeholder="Value"
                    value="{{ filter.target }}"
                    (change)="handleInputChange($event, filter.id, 'target')"
                  />
                </li>
                <li>
                  <button class="btn" (click)="this.removeFilter(filter.id)">Remove filter</button>
                </li>
              </ul>
              <div class="filter_add">
                <button class="btn btn--primary" (click)="this.addNewFilter($event)">Add filter</button>
              </div>
            </div>
            <app-form-error [visible]="form.controls[FILTER_KEY].hasError('filtersLength')">
              {{ 'CREATE_SERVICE_MODAL.FIELD_SERVICE_NAME_REQUIRED' | translate }}
            </app-form-error>
          </li>

          <li id="serviceDescriptions" name="serviceDescriptions" [ngClass]="{ 'is-open': accordionTabActive === 2 }">
            <div class="accordion__title" (click)="setAccordionTabActive(2)">
              {{ 'CREATE_SERVICE_MODAL.SERVICE_DESCRIPTION' | translate }}
            </div>
            <div class="accordion__content">
              <div class="row">
                <div class="col">
                  <h6>Service description:</h6>
                  <app-localization-input [markdownElement]="true" formControlName="{{ SERVICE_DESCRIPTION_KEY }}">
                  </app-localization-input>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <h6>Success message:</h6>
                  <app-localization-input [markdownElement]="true" formControlName="{{ SERVICE_SUCCESS_MESSAGE }}">
                  </app-localization-input>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <h6>Info message:</h6>
                  <app-localization-input [markdownElement]="true" formControlName="{{ SERVICE_INFO_MESSAGE }}">
                  </app-localization-input>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <h6>Failure message:</h6>
                  <app-localization-input [markdownElement]="true" formControlName="{{ SERVICE_FAILURE_MESSAGE }}">
                  </app-localization-input>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <h6>Success and failure message:</h6>
                  <app-localization-input
                    [markdownElement]="true"
                    formControlName="{{ SERVICE_SUCCESS_AND_FAILURE_MESSAGE }}"
                  >
                  </app-localization-input>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <h6>Missing events message:</h6>
                  <app-localization-input
                    [markdownElement]="true"
                    formControlName="{{ SERVICE_MISSING_EVENTS_MESSAGE }}"
                  >
                  </app-localization-input>
                </div>
              </div>
            </div>
          </li>
        </ul>

        <div class="row">
          <div class="col sm-8"></div>
          <div class="col sm-4">
            <button type="submit" name="acceptButton" class="btn btn--primary btn--expanded save-modal right">
              {{ 'CREATE_SERVICE_MODAL.BUTTON_SAVE' | translate }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
