<div
  *ngIf="
    rolesService.getBrandsWhereMaster().includes(service?.brand) ||
      service?.ownerIds?.some(this.rolesService.isCurrentUser);
    else elseBlock
  "
>
  <app-service-details-header [service]="service"></app-service-details-header>

  <div *ngIf="service" class="content content--boxed">
    <app-failures [service]="service"> </app-failures>
  </div>
</div>

<ng-template #elseBlock>
  <app-unathorized-access></app-unathorized-access>
</ng-template>
