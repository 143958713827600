<footer class="footer" style="display: flex">
  <div class="footer__links">
    <ul id="footer_list" style="justify-content: right">
      <li class="footer_list_item">
        <a href="https://www.volkswagenag.com/de/meta/provider-identification.html" target="_blank">{{
          'FOOTER.IMPRINT' | translate
        }}</a>
      </li>
      <li class="footer_list_item">
        <a routerLink="/dsgvo"> {{ 'FOOTER.LEGAL' | translate }}</a>
      </li>
      <li class="footer_list_item">
        <a routerLink="/contact">{{ 'FOOTER.CONTACT' | translate }}</a>
      </li>
    </ul>
    <div class="version">{{ 'FOOTER.VERSION' | translate }}:{{ version }}</div>
  </div>
</footer>
