export const environment = {
  production: false,
  e2edEnv: {
    issuer: 'https://idp.cloud.vwgroup.com/auth/realms/kums-mfa',
    clientId: 'idp-2506fece-fca6-4a0c-853b-1ed1750c253c-E2ED-prod',
    regions: ['en', 'de'],
    failureLanguages: ['de', 'en'],
    failureRoles: ['customerCare', 'customer', 'competenceCenter', 'workshop'],
    dateTimeFormat: 'dd-MM-yyyy HH:mm',
    dateFormat: 'dd-MM-yyyy',
    features: {
      whitelisting: true
    },
    roles: {
      VWAG: {
        ADMIN: ['VWAG_E2ED_ADMIN'],
        MASTER: ['VWAG_E2ED_MASTERDFSS', 'VWAG_E2ED_ADMIN'],
        BASIC: ['VWAG_E2ED_BASICDFSS', 'VWAG_E2ED_ADMIN']
      },
      SEAT: {
        ADMIN: ['SEAT_E2ED_ADMIN'],
        MASTER: ['SEAT_E2ED_MASTERDFSS', 'SEAT_E2ED_ADMIN'],
        BASIC: ['SEAT_E2ED_BASICDFSS', 'SEAT_E2ED_ADMIN']
      },
      PRODUCTION: {
        MASTER: ['VWAG_E2ED_MASTERDFSS']
      },
      TEST: {
        ADMIN: ['VWAG_E2ED_TEST_ADMIN'],
        MASTER: ['VWAG_E2ED_TEST_MASTERDFSS', 'VWAG_E2ED_TEST_ADMIN'],
        BASIC: ['VWAG_E2ED_TEST_BASICDFSS', 'VWAG_E2ED_TEST_ADMIN']
      }
    }
  }
};
