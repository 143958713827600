import { Component, Input, OnInit } from '@angular/core';
import { CoreService, CustomerService } from '../../../../shared/interfaces/service.interface';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { FilterCombination, StatusFilter } from '../../../../shared/interfaces/filter-combination.interface';
import { FilterCombinationsServiceClient } from '../../../../shared/services/filter-combinations.service';

@Component({
  selector: 'app-customer-service-filter-combinations-picker',
  templateUrl: './customer-service-filter-combinations-picker.component.html',
  styleUrls: ['./customer-service-filter-combinations-picker.component.scss']
})
export class CustomerServiceFilterCombinationsPickerComponent implements OnInit {
  @Input() customerService: CustomerService;
  @Input() coreServices: CoreService[];

  readonly coreServiceKey: string = 'coreService';
  coreServiceForm: UntypedFormGroup;
  allFilterCombinations: FilterCombination[] = [];
  sizeToShow: number;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private filterCombinationsServiceClient: FilterCombinationsServiceClient
  ) {}

  ngOnInit(): void {
    this.coreServiceForm = this.formBuilder.group({
      coreService: ['']
    });

    this.coreServiceForm.controls[this.coreServiceKey].valueChanges.subscribe((selectedValue: CoreService) => {
      this.filterCombinationsServiceClient
        .getFilterCombinationsByBrandAndCustomerServiceAndCoreService(
          selectedValue.brand,
          this.customerService.modId,
          selectedValue.modId
        )
        .subscribe((filterCombinations) => {
          this.allFilterCombinations = this.filterCombinationSort(filterCombinations);
          this.determineSize(filterCombinations);
        });
    });

    this.coreServiceForm.controls[this.coreServiceKey].setValue(this.coreServices[0]);
  }

  checkFilterCombination(filterCombinations: FilterCombination) {
    const coreService: CoreService = this.coreServiceForm.controls[this.coreServiceKey].value;
    filterCombinations.selected = !filterCombinations.selected;
    this.filterCombinationsServiceClient
      .updateFilterCombination(coreService.brand, this.customerService.modId, coreService.modId, filterCombinations)
      .subscribe((a) => this.updateSingleFilterCombination(filterCombinations, (a as unknown) as FilterCombination));
  }

  private updateSingleFilterCombination(oldObj: FilterCombination, newObj: FilterCombination) {
    const i = this.allFilterCombinations.findIndex((v) => v === oldObj);
    this.allFilterCombinations[i] = newObj;
  }

  checkAllFilterCombinations(select: boolean) {
    const coreService: CoreService = this.coreServiceForm.controls[this.coreServiceKey].value;
    if (this.allFilterCombinations.length > 0 && this.allFilterCombinations[0].selected !== select) {
      this.filterCombinationsServiceClient
        .selectOrDeselectAllFilterCombinations(coreService.brand, this.customerService.modId, coreService.modId, select)
        .subscribe((a) =>
          this.storeFilterCombination(this.allFilterCombinations, (a as unknown) as FilterCombination[])
        );
    }
  }

  determineSize(filterCombinations: FilterCombination[]) {
    this.sizeToShow = 21;
    if (this.sizeToShow >= filterCombinations.length) {
      this.sizeToShow = filterCombinations.length;
    } else {
      this.sizeToShow = 21;
    }
  }

  loadRemainingData() {
    this.sizeToShow = this.allFilterCombinations.length;
  }

  filterCombinationSort(filterCombinations: FilterCombination[]) {
    return filterCombinations.sort(
      (combination1, combination2) =>
        combination1.useCase.localeCompare(combination2.useCase) ||
        combination1.action.localeCompare(combination2.action) ||
        combination1.component.localeCompare(combination2.component) ||
        Number(combination1.selected) - Number(combination2.selected)
    );
  }

  public get statusFilter(): typeof StatusFilter {
    return StatusFilter;
  }

  statusFilterChange(filterCombination: FilterCombination, statusFilter: StatusFilter) {
    if (statusFilter === StatusFilter.ERROR) {
      filterCombination.showingError = !filterCombination.showingError;
    } else if (statusFilter === StatusFilter.SUCCESS) {
      filterCombination.showingSuccess = !filterCombination.showingSuccess;
    } else {
      filterCombination.showingInfo = !filterCombination.showingInfo;
    }

    const coreService: CoreService = this.coreServiceForm.controls[this.coreServiceKey].value;

    this.filterCombinationsServiceClient
      .updateFilterCombination(coreService.brand, this.customerService.modId, coreService.modId, filterCombination)
      .subscribe((a) => this.updateSingleFilterCombination(filterCombination, (a as unknown) as FilterCombination));
  }

  private storeFilterCombination(oldObj: FilterCombination | FilterCombination[], newObj: FilterCombination[]) {
    if (JSON.stringify(oldObj) !== JSON.stringify(newObj)) {
      this.allFilterCombinations = newObj;
    }
  }
}
