<div class="modal">
  <div class="modal__wrapper">
    <div class="modal-inner-wrapper">
      <div class="modal-head-wrapper">
        <h1 class="modal__title">
          {{ 'NEWSLETTER-DASHBOARD.CREATE-MODAL.TITLE' | translate }}
        </h1>
        <div class="modal__close" (click)="closed.emit()"></div>
      </div>
      <div class="modal__content">
        <form [formGroup]="form" (ngSubmit)="confirmForm()">
          <div class="flags-wrapper horizontal-margins-small">
            <div
              *ngFor="let language of languages; let i = index"
              class="btn btn--fab"
              [ngClass]="selectedLanguage === language ? 'btn--primary' : ''"
              id="LANGUAGE-{{ language.toLocaleUpperCase() }}-BUTTON"
            >
              <img
                class="taskbar__picture"
                src="assets/images/language-images/{{ language }}.png"
                alt="{{ language }}"
                (click)="selectedLanguage = language"
                title="LANGUAGE-{{ language.toLocaleUpperCase() }}-BUTTON"
              />
            </div>
          </div>
          <ng-container formGroupName="title">
            <div
              class="wrapper wrapper--input mar-large"
              *ngFor="let language of languages"
              [ngClass]="language !== selectedLanguage ? 'hidden-controls' : ''"
            >
              <label for="{{ language }}">{{ 'NEWSLETTER-DASHBOARD.CREATE-MODAL.TITLE-LABEL' | translate }}</label>
              <input id="{{ language }}" type="text" formControlName="{{ language }}" required />
            </div>

            <app-form-error
              *ngFor="let language of languages"
              [visible]="form.get('title.' + language).hasError('required') && submitted"
            >
              {{ 'NEWSLETTER-DASHBOARD.CREATE-MODAL.ERROR.TITLE.' + language | translate }}
            </app-form-error>
          </ng-container>
          <ng-container formGroupName="content">
            <div
              *ngFor="let language of languages"
              [ngClass]="language !== selectedLanguage ? 'hidden-controls' : ''"
              class="wrapper wrapper--input mar-large"
            >
              <label for="{{ language }}">
                {{ 'NEWSLETTER-DASHBOARD.CREATE-MODAL.CONTENT-LABEL' | translate }}
                <textarea
                  placeholder="Hi Dave …"
                  id="{{ language }}"
                  formControlName="{{ language }}"
                  rows="6"
                  required
                ></textarea>
              </label>
            </div>

            <app-form-error
              *ngFor="let language of languages"
              [visible]="form.get('content.' + language).hasError('required') && submitted"
            >
              {{ 'NEWSLETTER-DASHBOARD.CREATE-MODAL.ERROR.CONTENT.' + language | translate }}
            </app-form-error>
          </ng-container>

          <div class="wrapper wrapper--input mar-large">
            <label for="date">
              {{ 'NEWSLETTER-DASHBOARD.CREATE-MODAL.DATE-LABEL' | translate }}
              <input
                class="form-control"
                id="expirationDate"
                type="date"
                [min]="earliestDate()"
                formControlName="expirationDate"
                required
              />
            </label>
          </div>

          <app-form-error [visible]="form.controls['expirationDate'].hasError('required') && submitted">
            {{ 'NEWSLETTER-DASHBOARD.CREATE-MODAL.ERROR.EXPIRATION-DATE' | translate }}
          </app-form-error>

          <div class="mar-large flex flex--justify-end">
            <button type="submit" class="btn btn--primary btn--expanded save-modal" id="submit-newsletter">
              {{ 'NEWSLETTER-DASHBOARD.CREATE-MODAL.SUBMIT' | translate }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
