<ng-content></ng-content>

<div *ngIf="markdownViewOpened" class="markdown-float-wrapper">
  <div class="markdown-body" markdown [data]="data"></div>
</div>

<div class="icon-wrapper">
  <mat-icon (click)="this.markdownViewOpened = !this.markdownViewOpened" class="markdown-preview">
    remove_red_eye
  </mat-icon>
</div>
