import { environment } from 'src/environments/environment';

export const e2edEnv = environment.e2edEnv;

export const env = {
  issuer: e2edEnv.issuer,
  clientId: e2edEnv.clientId,
  regions: e2edEnv.regions,
  failureLanguages: e2edEnv.failureLanguages,
  failureRoles: e2edEnv.failureRoles,
  dateTimeFormat: e2edEnv.dateTimeFormat,
  dateFormat: e2edEnv.dateFormat,
  features: exportFeatureMap(),
  roleMap: e2edEnv.roles
};

function exportFeatureMap(): Map<string, boolean> {
  const featureMap = new Map<string, boolean>();
  for (const value in e2edEnv.features) {
    if (e2edEnv.features[value]) {
      featureMap.set(value, e2edEnv.features[value]);
    }
  }
  return featureMap;
}
