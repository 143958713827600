<div *ngIf="rolesService.getBrandsWhereMaster().includes(customerService?.brand); else elseBlock">
  <app-service-details-header [service]="customerService"></app-service-details-header>

  <app-customer-service-filter-combinations-picker
    *ngIf="coreServices.length > 0 && coreServices.length === requestsDone"
    [coreServices]="coreServices.sort()"
    [customerService]="customerService"
  ></app-customer-service-filter-combinations-picker>

  <div *ngIf="coreServices.length === 0" class="content content--boxed">
    <div class="row row--container">
      <h2 class="subtitle">{{ 'CUSTOMER_SERVICE_DETAIL_PAGE.NO_CORE_SERVICES' | translate }}</h2>
    </div>
  </div>
</div>

<ng-template #elseBlock>
  <app-unathorized-access></app-unathorized-access>
</ng-template>
