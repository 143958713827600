import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Role } from 'src/app/shared/constants';
import { RolesService } from 'src/app/shared/services/roles-service.service';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AvatarComponent implements OnInit {
  public Role = Role;

  constructor(private rolesService: RolesService) {}

  ngOnInit(): void {}

  getDominantRole(): Role {
    return this.rolesService.getDominantRole();
  }
}
