import { Localization, Solution } from './failure.interface';

export enum Generation {
  MOD_4 = 'MOD 4'
}

export interface Service {
  brand: string;
  modId: string;
  name: string;
  generation: Generation;
  launchDate: string;
  supportedLanguages: string[];
  serviceDescriptions: Localization;
  isUserIdRequired: boolean;
  isVinRequired: boolean;
}

export interface CoreService extends Service {
  ownerName: string;
  ownerIds: string[];
  undefinedSolution: Solution;
  undefinedSolutionTitle: Localization;
  absentEvents: Solution;
  additionalParameters: AdditionalParameter[];
}

export interface CustomerService extends Service {
  modIds: string[];
}

export interface CustomizedService extends CustomerService {
  successMessages: Localization;
  failureMessages: Localization;
  successAndFailureMessages: Localization;
  missingEventMessages: Localization;
  infoMessages: Localization;
}

export interface CustomizedService extends Service {
  filter: string;
  testKey: string;
}

export interface AdditionalParameter {
  parameterName: string;
  mlaasParameterPath: string;
}
