import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Constants } from '../constants';
import { CoreService, CustomerService, CustomizedService } from '../interfaces/service.interface';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NotificationService } from '../../components/notification/notification.service';

export interface ServiceClient<T> {
  getItems(): Observable<T[]>;
  createItem(service: T): Observable<unknown>;
  updateItem(service: T): Observable<unknown>;
  deleteItem(service: T): Observable<any>;
}

export abstract class AbstractServicesService<T> implements ServiceClient<T> {
  constructor(protected http: HttpClient, private notifications: NotificationService) {}

  abstract allItemsEndpoint(): string;
  abstract createItemEndpoint(item: T): string;
  abstract updateItemEndpoint(item: T): string;
  abstract deleteItemEndpoint(item: T): string;

  getItems(): Observable<T[]> {
    return this.http.get<T[]>(this.allItemsEndpoint()).pipe(catchError(this.handleError('items retrieval', [])));
  }

  createItem(service: T) {
    return this.http.post(this.createItemEndpoint(service), service).pipe(catchError(this.handleError('update')));
  }

  updateItem(service: T) {
    return this.http.put(this.updateItemEndpoint(service), service).pipe(catchError(this.handleError('update')));
  }

  deleteItem(service: T): Observable<any> {
    return this.http.delete(this.deleteItemEndpoint(service)).pipe(catchError(this.handleError('deletion', null)));
  }

  protected handleError<K>(operation = 'operation', result?: K) {
    return (error: HttpErrorResponse): Observable<K> => {
      if (typeof error.error === 'string') {
        this.notifications.error(operation + ' failed: ' + error.status, error.error);
      } else {
        const requestId = error.headers.has('e2ed-requestId')
          ? ' requestId: ' + error.headers.get('e2ed-requestId')
          : '';

        this.notifications.error(
          operation + ' failed, ' + requestId,
          error.status + ' ' + error.statusText + ': ' + error.error.message
        );
      }
      return of(result as K);
    };
  }
}

@Injectable({
  providedIn: 'root'
})
export class CustomizedServiceClient extends AbstractServicesService<CustomizedService> {
  constructor(http: HttpClient, notifications: NotificationService) {
    super(http, notifications);
  }

  allItemsEndpoint = () => Constants.CUSTOMIZED_SERVICES_URL;
  createItemEndpoint = (service) => {
    return `${Constants.SERVICE_BY_BRAND_URL}/${service.brand}/customized-services/${service.modId}`;
  };
  updateItemEndpoint = (service) =>
    `${Constants.SERVICE_BY_BRAND_URL}/${service.brand}/customized-services/${service.modId}`;
  deleteItemEndpoint = (service) =>
    `${Constants.SERVICE_BY_BRAND_URL}/${service.brand}/customized-services/${service.modId}`;

  getCustomizedServiceByBrandAndModId(brand: string, modId: string): Observable<CustomerService> {
    return this.http.get<CustomerService>(`${Constants.SERVICE_BY_BRAND_URL}/${brand}/customized-services/${modId}`);
  }
}

@Injectable({
  providedIn: 'root'
})
export class CustomerServiceClient extends AbstractServicesService<CustomerService> {
  constructor(http: HttpClient, notifications: NotificationService) {
    super(http, notifications);
  }

  allItemsEndpoint = () => `${Constants.CUSTOMER_SERVICES_URL}`;
  createItemEndpoint = (service) =>
    `${Constants.SERVICE_BY_BRAND_URL}/${service.brand}/customer-services/${service.modId}`;
  updateItemEndpoint = (service) =>
    `${Constants.SERVICE_BY_BRAND_URL}/${service.brand}/customer-services/${service.modId}`;
  deleteItemEndpoint = (service) =>
    `${Constants.SERVICE_BY_BRAND_URL}/${service.brand}/customer-services/${service.modId}`;

  getCustomerServiceByBrandAndModId(brand: string, modId: string): Observable<CustomerService> {
    return this.http.get<CustomerService>(`${Constants.SERVICE_BY_BRAND_URL}/${brand}/customer-services/${modId}`);
  }
}

@Injectable({
  providedIn: 'root'
})
export class CoreServiceClient extends AbstractServicesService<CoreService> {
  constructor(http: HttpClient, notifications: NotificationService) {
    super(http, notifications);
  }

  allItemsEndpoint() {
    return `${Constants.SERVICES_URL}`;
  }
  createItemEndpoint = (service) => `${Constants.SERVICE_BY_BRAND_URL}/${service.brand}/services/${service.modId}`;
  updateItemEndpoint = (service) => `${Constants.SERVICE_BY_BRAND_URL}/${service.brand}/services/${service.modId}`;
  deleteItemEndpoint = (service) => `${Constants.SERVICE_BY_BRAND_URL}/${service.brand}/services/${service.modId}`;

  getServiceByBrandAndModId(brand: string, modId: string) {
    return this.http
      .get(`${Constants.SERVICE_BY_BRAND_URL}/${brand}/services/${modId}`)
      .pipe(catchError(this.handleError('getting service ' + brand + ':' + modId, null)));
  }
}
