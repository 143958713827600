export interface FilterCombination {
  useCase: string;
  action: string;
  component: string;
  selected: boolean;
  showingError: boolean;
  showingSuccess: boolean;
  showingInfo: boolean;
}

export enum StatusFilter {
  SUCCESS,
  ERROR,
  INFO
}
