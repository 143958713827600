<div class="modal">
  <div class="modal__wrapper">
    <h1 class="modal__title">
      {{ 'CREATE_SERVICE_MODAL.MODAL_TITLE_' + (creationMode ? 'CREATE' : 'EDIT') | translate }}
    </h1>
    <div class="modal__close" (click)="close()"></div>
    <div class="modal__content">
      <form (ngSubmit)="onSubmit()" [formGroup]="form">
        <ul id="serviceContent" name="serviceContent" class="accordion">
          <li id="basicInfoTab" name="basicInfoTab" [ngClass]="{ 'is-open': accordionTabActive === 0 }">
            <div class="accordion__title" (click)="setAccordionTabActive(0)">
              {{ 'CREATE_SERVICE_MODAL.BASIC_INFO' | translate }}
            </div>
            <div class="accordion__content">
              <div class="row">
                <div class="col lg-7 md-7" [ngClass]="{ 'wrapper wrapper--input is-inactive': !creationMode }">
                  <label for="name">
                    {{ 'CREATE_SERVICE_MODAL.NAME' | translate }}
                    <input class="form-control" formControlName="{{ NAME_KEY }}" type="text" id="name" name="name" />
                  </label>
                </div>

                <div class="col lg-5 md-5" [ngClass]="{ 'wrapper wrapper--input is-inactive': !creationMode }">
                  <label for="modId">
                    {{ 'CREATE_SERVICE_MODAL.MOD_ID' | translate }}
                    <input
                      class="form-control"
                      formControlName="{{ MOD_ID_KEY }}"
                      type="text"
                      id="modId"
                      name="modId"
                    />
                  </label>
                </div>

                <app-form-error [visible]="form.controls[NAME_KEY].hasError('required') && submitted">
                  {{ 'CREATE_SERVICE_MODAL.FIELD_SERVICE_NAME_REQUIRED' | translate }}
                </app-form-error>
                <app-form-error [visible]="form.controls[MOD_ID_KEY].hasError('required') && submitted">
                  {{ 'CREATE_SERVICE_MODAL.FIELD_MOD_ID_REQUIRED' | translate }}
                </app-form-error>

                <app-form-error [visible]="form.controls[NAME_KEY].hasError('included') && submitted">
                  {{
                    'CREATE_SERVICE_MODAL.SERVICE_NAME_ALREADY_EXISTS'
                      | translate: { x: form.get(this.NAME_KEY).value, y: form.get(this.BRAND_KEY).value }
                  }}
                </app-form-error>
                <app-form-error [visible]="form.controls[MOD_ID_KEY].hasError('included') && submitted">
                  {{
                    'CREATE_SERVICE_MODAL.SERVICE_MODID_ALREADY_EXISTS'
                      | translate: { x: form.get(this.MOD_ID_KEY).value, y: form.get(this.BRAND_KEY).value }
                  }}
                </app-form-error>
              </div>

              <div class="row">
                <div class="col lg-4 md-4" [ngClass]="{ 'wrapper wrapper--input is-inactive': !creationMode }">
                  <label for="brand">
                    {{ 'CREATE_SERVICE_MODAL.BRAND' | translate }}
                    <div class="wrapper wrapper--select">
                      <select id="brand" name="brand" formControlName="{{ BRAND_KEY }}" (change)="handleBrandChange()">
                        <option *ngFor="let brand of brands()" [value]="brand">
                          {{ brand }}
                        </option>
                      </select>
                    </div>
                  </label>
                </div>

                <div class="col lg-4 md-4">
                  <label for="generation">
                    {{ 'CREATE_SERVICE_MODAL.GENERATION' | translate }}
                    <div class="wrapper wrapper--select">
                      <select
                        id="generation"
                        name="generation"
                        formControlName="{{ GENERATION_KEY }}"
                        (change)="updateServices()"
                      >
                        <option *ngFor="let generation of GENERATION_KEYS" [ngValue]="Generation[generation]">
                          {{ Generation[generation] }}
                        </option>
                      </select>
                    </div>
                  </label>
                  <div>
                    <input type="checkbox" id="vin" name="vin" formControlName="{{ VIN_REQUIRED }}" />
                    <label for="vin">{{ 'CREATE_SERVICE_MODAL.VIN_REQUIRED' | translate }}</label>
                  </div>
                  <div>
                    <input type="checkbox" id="userId" name="userId" formControlName="{{ USER_ID_REQUIRED }}" />
                    <label for="userId">{{ 'CREATE_SERVICE_MODAL.USERID_REQUIRED' | translate }}</label>
                  </div>
                  <app-form-error [visible]="form.controls[GENERATION_KEY].hasError('required') && submitted">
                    {{ 'CREATE_SERVICE_MODAL.FIELD_GENERATION_REQUIRED' | translate }}
                  </app-form-error>
                </div>

                <div class="col lg-4 md-4">
                  <label for="date">
                    {{ 'CREATE_SERVICE_MODAL.DATE' | translate }}
                    <input class="form-control" formControlName="{{ DATE_KEY }}" id="date" type="date" name="date" />
                  </label>
                </div>
              </div>

              <div class="row">
                <div class="col lg-12 xl-7">
                  <label for="supportedLanguages">
                    {{ 'CREATE_SERVICE_MODAL.SUPPORTED_LANGUAGES' | translate }}
                    <div class="wrapper">
                      <app-multi-value-input
                        formControlName="{{ SUPPORTED_LANGUAGES_KEY }}"
                        (itemAdded)="onSupportedLanguageAdded($event)"
                        (itemRemoved)="onSupportedLanguageRemoved($event)"
                      >
                      </app-multi-value-input>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </li>

          <li id="serviceDescriptions" name="serviceDescriptions" [ngClass]="{ 'is-open': accordionTabActive === 1 }">
            <div class="accordion__title" (click)="setAccordionTabActive(1)">
              {{ 'CREATE_SERVICE_MODAL.SERVICE_DESCRIPTION' | translate }}
            </div>
            <div class="accordion__content">
              <div class="row">
                <div class="col">
                  <app-localization-input [markdownElement]="true" formControlName="{{ SERVICE_DESCRIPTION_KEY }}">
                  </app-localization-input>
                </div>
              </div>
            </div>
          </li>
        </ul>

        <br />

        <div class="col sm-12" formArrayName="modIds">
          <label for="modIds">
            {{ 'DASHBOARD.CORE_SERVICES' | translate }}
          </label>

          <div *ngFor="let service of brandServices; index as i">
            <div>
              <input
                type="checkbox"
                [id]="'selected_service_' + service.modId"
                name="{{ service.modId }}"
                [value]="service.modId"
                (change)="onCheckChange($event)"
                class="service-select"
                [checked]="initialObject.modIds ? initialObject.modIds.includes(service.modId) : false"
              />
              <label [for]="'selected_service_' + service.modId">{{ service.name }}</label>
            </div>
          </div>
        </div>
        <br />

        <div class="row">
          <div class="col sm-8"></div>
          <div class="col sm-4">
            <button type="submit" name="acceptButton" class="btn btn--primary btn--expanded save-modal right">
              {{ 'CREATE_SERVICE_MODAL.BUTTON_SAVE' | translate }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
