import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NotificationService } from '../../components/notification/notification.service';

export interface ItemsClient<T> {
  getItems(): Observable<T[]>;
  createItem(service: T): Observable<unknown>;
  updateItem(service: T): Observable<unknown>;
  deleteItem(service: T): Observable<any>;
}

export abstract class AbstractItemService<T> implements ItemsClient<T> {
  constructor(protected http: HttpClient, private notifications: NotificationService) {}

  abstract allItemsEndpoint(): string;
  abstract createItemEndpoint(item: T): string;
  abstract updateItemEndpoint(item: T): string;
  abstract deleteItemEndpoint(item: T): string;

  getItems(): Observable<T[]> {
    return this.http.get<T[]>(this.allItemsEndpoint()).pipe(catchError(this.handleError('items retrieval', [])));
  }

  createItem(service: T) {
    return this.http.put(this.createItemEndpoint(service), service).pipe(catchError(this.handleError('update')));
  }

  updateItem(service: T) {
    return this.http.put(this.updateItemEndpoint(service), service).pipe(catchError(this.handleError('update')));
  }

  deleteItem(service: T): Observable<any> {
    return this.http.delete(this.deleteItemEndpoint(service)).pipe(catchError(this.handleError('deletion', null)));
  }

  protected handleError<K>(operation = 'operation', result?: K) {
    return (error: HttpErrorResponse): Observable<K> => {
      if (typeof error.error === 'string') {
        this.notifications.error(operation + ' failed: ' + error.status, error.error);
      } else {
        const requestId = error.headers.has('e2ed-requestId')
          ? ' requestId: ' + error.headers.get('e2ed-requestId')
          : '';

        this.notifications.error(
          operation + ' failed, ' + requestId,
          error.status + ' ' + error.statusText + ': ' + error.error.message
        );
      }
      return of(result as K);
    };
  }
}
