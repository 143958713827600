<div
  *ngIf="
    features.get('whitelisting') &&
      rolesService.checkIfUserHasPermission(ADMIN_ROLE);
    else elseBlock
  "
>
  <div class="row row--container mar-top-large">
    <div class="col sm-12">
      <div class="card mar-top-large">
        <div class="card__content">
          <div class="row">
            <div
              class="col sm-6 pad-top-medium pad-bottom-medium"
              name="whitelistCount"
            >
              <span class="h4">{{ 'ADMIN.WHITELIST' | translate }}</span>
              <span class="tag tag--secondary mar-left-large">
                {{ whitelistedClients.length }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row row--container">
    <div class="col sm-12">
      <div class="card mar-top-large">
        <div class="card__content">
          <div class="row">
            <div class="col sm-10 cursor-pointer">
              <h2 class="subtitle">{{ 'ADMIN.CLIENT_LIST' | translate }}</h2>
            </div>
          </div>
          <ul class="list list--hover">
            <li
              *ngFor="let client of whitelistedClients"
              class="horizontal-margins-medium"
            >
              <span class="content">{{ client.clientId }}</span>
              <button
                class="btn btn--primary whitelist_delete_button"
                type="submit"
                id="deleteUser-{{ client.clientId }}"
                (click)="deleteWhitelistedClient(client)"
              >
                {{ 'ADMIN.DELETE' | translate }}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="row row--container">
    <div class="col sm-12">
      <div class="card mar-top-large">
        <div class="card__content">
          <h1 class="h5">{{ 'ADMIN.ADD_NEW' | translate }}</h1>
          <form #myForm="ngForm" (ngSubmit)="addWhitelistedClient(myForm)">
            <div class="row pad-top-medium">
              <div class="col sm-8">
                <input
                  placeholder="Enter client id"
                  name="clientId"
                  id="clientIdInput"
                  class=""
                  type="text"
                  required
                  ngModel
                />
              </div>
              <div>
                <button
                  class="btn btn--primary"
                  name="addUser"
                  id="addUser"
                  type="submit"
                >
                  <span class="icon i-user-add"></span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #elseBlock>
  <app-unathorized-access></app-unathorized-access>
</ng-template>
