<div class="modal">
  <div class="modal__wrapper">
    <div class="modal-inner-wrapper">
      <div class="modal-head-wrapper">
        <h1 class="modal__title">
          {{ title }}
        </h1>
        <div class="modal__close" (click)="confirmDialog(false)"></div>
      </div>
      <div class="modal__content">
        <div>
          {{ content }}
        </div>
        <div class="flex flex--justify-center row mar-top-large">
          <button
            type="submit"
            class="btn btn--primary btn--expanded mar-large"
            (click)="confirmDialog(true)"
            name="confirmDeletion"
          >
            {{ confirmContent }}
          </button>
          <button
            type="submit"
            class="btn btn--primary btn--expanded mar-large"
            (click)="confirmDialog(false)"
          >
            {{ cancelContent }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
