<div *ngIf="isMaster(service) || service.ownerIds?.some(this.rolesService.isCurrentUser)">
  <div class="row row--container">
    <label for="filter" class="col md-9"
      >Filter
      <app-multi-value-input
        class="filter-bar"
        name="filter"
        [items]="filters"
        (itemAdded)="onFilterUpdated()"
        (itemRemoved)="onFilterUpdated()"
      ></app-multi-value-input>
    </label>
  </div>
  <div class="service-detail-failure-list-component">
    <div class="table-section">
      <div class="flex pad-large" *ngIf="getMarkedFailuresLength() === 0">
        <div class="flex__item--grow">
          <strong>{{ 'SERVICE_DETAIL_FAILURE_LIST.FAILUREAMOUNT' | translate }}</strong>
          <div class="tag tag--pill tag--primary mar-left-medium">
            {{ allItems?.length }}
          </div>
        </div>
        <button
          name="fileDownloadButton"
          class="flex__item--flex-none mar-horizontal-medium btn--flat cursor-pointer"
          (click)="doDownloadFile()"
        >
          <i class="icon i-download pad-right-small"></i
          ><strong>{{ 'SERVICE_DETAIL_FAILURE_LIST.EXCELEXPORT' | translate }}</strong>
        </button>
        <button
          name="fileUploadButton"
          class="flex__item--flex-none mar-horizontal-medium btn--flat cursor-pointer"
          (click)="fileupload.click()"
        >
          <i class="icon i-upload pad-right-small"></i
          ><strong>{{ 'SERVICE_DETAIL_FAILURE_LIST.EXCELIMPORT' | translate }}</strong>
          <input
            name="uploadFileInput"
            style="display: none"
            type="file"
            #fileupload
            id="fileupload"
            (change)="doUploadFile()"
          />
        </button>
        <button class="flex__item--flex-none mar-horizontal-medium btn--flat cursor-pointer" (click)="createNewItem()">
          <i class="icon icon-blue-plus pad-right-small"></i
          ><strong>{{ 'SERVICE_DETAIL_FAILURE_LIST.ADDFAILURE' | translate }}</strong>
        </button>
      </div>

      <div class="flex bg-primary marked-failures-section pad-large" *ngIf="getMarkedFailuresLength() > 0">
        <div name="removeFailure" class="flex__item--flex-none cursor-pointer" (click)="askDeleteMarkedFailures()">
          <i class="icon i-delete pad-right-large"></i>
          <span class="text-bold">{{ 'SERVICE_DETAIL_FAILURE_LIST.TABLE.MARKED.DELETE' | translate }}</span>
        </div>
        <div style="flex-grow: 1"></div>
        <div class="table-action flex__item--flex-none">
          {{ 'SERVICE_DETAIL_FAILURE_LIST.TABLE.MARKED.NUMBER' | translate: { x: getMarkedFailuresLength() } }}
        </div>
        <div class="table-action flex__item--flex-none cursor-pointer" (click)="unmarkAllFailures()">
          <span class="text-bold">{{ 'SERVICE_DETAIL_FAILURE_LIST.TABLE.MARKED.CANCEL' | translate }}</span>
        </div>
      </div>

      <table class="table cursor-default">
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                id="heading-checkbox"
                (change)="toggleAllFailuresMarked()"
                [checked]="allFailuresMarked === true"
              /><label for="heading-checkbox"></label>
            </th>
            <th>
              <div class="arrow arrow--down"></div>
              {{ 'SERVICE_DETAIL_FAILURE_LIST.TABLE.HEADING.FEHLERNAME' | translate }}
            </th>
            <th>
              <div class="arrow arrow--down"></div>
              {{ 'SERVICE_DETAIL_FAILURE_LIST.TABLE.HEADING.STATUSCODE' | translate }}
            </th>
            <th>
              <div class="arrow arrow--down"></div>
              {{ 'SERVICE_DETAIL_FAILURE_LIST.TABLE.HEADING.USECASE' | translate }}
            </th>
            <th>
              <div class="arrow arrow--down"></div>
              {{ 'SERVICE_DETAIL_FAILURE_LIST.TABLE.HEADING.ACTIONNAME' | translate }}
            </th>
            <th>
              <div class="arrow arrow--down"></div>
              {{ 'SERVICE_DETAIL_FAILURE_LIST.TABLE.HEADING.COMPONENT' | translate }}
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <app-failure-item
            *ngFor="let failure of itemsToDisplay"
            [failure]="failure"
            (edit)="onEdit($event)"
            (delete)="onDelete($event)"
            class="table-line"
            id="item-{{ failure.name }}"
            (toggle)="toggleFailureMarked(failure)"
            [checked]="markedFailures[failure.name]"
          ></app-failure-item>
        </tbody>
      </table>
    </div>

    <div class="e2e-pagination flex flex--direction-column flex--justify-center">
      <p class="center-div pagination__load-more-text">
        {{
          'SERVICE_DETAIL_FAILURE_LIST.NUM_OF_RESULTS' | translate: { x: itemsToDisplay?.length, y: allItems?.length }
        }}
      </p>
      <div class="center-div">
        <progress value="{{ loadedPercentage || 0 }}" max="100"></progress>
      </div>
      <div class="center-div horizontal-margins-small">
        <button class="btn btn--tertiary show-all" (click)="loadRemainingData()" [disabled]="isAllShown()">
          {{ 'SERVICE_DETAIL_FAILURE_LIST.SHOW_ALL' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<app-create-failure-modal
  *ngIf="editedItem !== null && editedItem !== undefined"
  (closed)="editWindowClosed()"
  (onsubmit)="itemCreated($event)"
  [initialObject]="editedItem"
  [service]="service"
  [existingFailures]="allItems"
></app-create-failure-modal>

<app-affirmation-modal
  (closed)="onDeletionAffirmationClosed($event)"
  *ngIf="openDeletionAffirmation"
  [title]="'SERVICE_DETAIL_FAILURE_LIST.AFFIRMATION_MODAL.TITLE' | translate"
  [content]=""
  [confirmContent]="'AFFIRMATION_MODAL.DELETE_YES' | translate"
  [cancelContent]="'AFFIRMATION_MODAL.DELETE_NO' | translate"
></app-affirmation-modal>
