import { Component, OnInit } from '@angular/core';

import { CoreService } from '../../../../shared/interfaces/service.interface';
import { RolesService } from '../../../../shared/services/roles-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CoreServiceClient } from 'src/app/shared/services/services.service';
import { ItemsBaseDirective } from '../../items-base.directive';

@Component({
  selector: 'app-core-services',
  templateUrl: './core-services.component.html',
  styleUrls: ['./core-services.component.scss']
})
export class CoreServicesComponent extends ItemsBaseDirective<any> implements OnInit {
  itemConstructor(): CoreService {
    return {} as CoreService;
  }
  constructor(
    private services: CoreServiceClient,
    public rolesService: RolesService,
    router: Router,
    route: ActivatedRoute
  ) {
    super(services, rolesService, router, route);
  }

  ngOnInit(): void {
    if (!this.filters.length) {
      this.setVisibilityFilter((s: CoreService[]) => s.slice(0, 9));
    }
    this.refreshItems();
  }

  navigateToServiceDetails(service: CoreService) {
    if (this.isMaster(service) || service.ownerIds?.some(this.rolesService.isCurrentUser)) {
      this.router.navigate(['/brand/' + service.brand + '/service/' + service.modId]);
    } else {
      console.log('user not allowed to see service content');
    }
  }

  fieldsToFilterOn() {
    return ['name', 'modId', 'brand', 'ownerName', 'generation', 'launchDate'];
  }
}
