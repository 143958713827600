import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject } from 'rxjs';
import { env } from 'src/app/environment';
import { authConfig } from '../../auth.config';
import { JwtToken, KeycloakResource } from '../interfaces/jtw.interface';
import { MappedRoles } from '../models/mapped-roles.model';
import { User } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private oauthService: OAuthService) {}

  private user = new BehaviorSubject<User>(null);

  getUser(): BehaviorSubject<User> {
    return this.user;
  }

  login() {
    this.oauthService.configure(authConfig);
    this.oauthService.setStorage(sessionStorage);
    this.oauthService.oidc = true; // ID_Token
    this.oauthService.setupAutomaticSilentRefresh();

    return this.oauthService
      .loadDiscoveryDocumentAndLogin()
      .then((result) => {
        if (result) {
          const decodedToken: JwtToken = JSON.parse(atob(this.oauthService.getAccessToken().split('.')[1]));

          const rawRoles = this.flattenRoles(decodedToken.resource_access);

          const mappedRoles = this.mapRoles(rawRoles);

          this.user.next({
            rawRoles,
            mappedRoles,
            name: decodedToken.given_name + ' ' + decodedToken.family_name,
            username: decodedToken.preferred_username
          });
        }
      })
      .catch((exp) => {
        console.error('', exp);
      });
  }

  logout() {
    this.oauthService.logOut();
    this.user.next(null);
  }

  flattenRoles(resources: { [resource: string]: KeycloakResource }): string[] {
    return Object.values(resources || {})
      .filter((resource) => !!resource)
      .flatMap((resource) => resource.roles);
  }

  mapRoles(rawRoles: string[]): MappedRoles {
    // Iterating over roles defined in environment
    return Object.entries(env.roleMap).reduce((prev, [brand, brandMapping]) => {
      const mappedBrandRoles = Object.entries(brandMapping).flatMap(([role, roleMapping]) => {
        const hasRole = rawRoles.some((rawRole) => roleMapping.includes(rawRole));

        return hasRole ? role : ([] as string[]);
      });

      return {
        ...prev,
        [brand]: mappedBrandRoles
      };
    }, {} as MappedRoles);
  }
}
