<div class="col md-9 filter--component">
  <div class="row pad-horizontal-medium pad-bottom-medium">
    <label class="services--filter h5">Filter</label>
    <div class="flex--spacer"></div>
    <div class="prepared__filters">
      <div class="brand__filter prepared__filter__item" *ngFor="let brand of brands">
        <div class="btn btn--fab mar-horizontal-medium" id="{{ brand }}-button" (click)="addPreparedFilter.emit(brand)">
          <img alt="{{ brand }}" class="taskbar__picture" src="/assets/images/brand-logos/{{ brand }}.png" />
        </div>
      </div>

      <div class="mod__filter prepared__filter__item" *ngFor="let mod of [['M4', 'MOD 4']]">
        <div class="btn btn--fab mar-horizontal-medium" (click)="addPreparedFilter.emit(mod[1])">
          <div class="circle">
            <label>{{ mod[0] }}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-multi-value-input
    name="filter"
    [items]="filters"
    (itemAdded)="filterUpdated.emit()"
    (itemRemoved)="filterUpdated.emit()"
  >
  </app-multi-value-input>
</div>
