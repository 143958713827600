<tr
  [ngClass]="{
    error: failure.type === FailureType.ERROR,
    success: failure.type === FailureType.SUCCESS
  }"
>
  <td>
    <input type="checkbox" id="select_{{ failure.name }}" [checked]="checked" (change)="onChecked()" />
    <label for="select_{{ failure.name }}"></label>
  </td>
  <td title="{{ failure.name }}" class="failure-name">{{ failure.name }}</td>
  <td title="{{ failure.statusCode }}">{{ failure.statusCode }}</td>
  <td title="{{ failure.useCase }}">{{ failure.useCase }}</td>
  <td title="{{ failure.action }}">{{ failure.action }}</td>
  <td title="{{ failure.component }}">{{ failure.component }}</td>
  <td class="options-menu">
    <details class="dropdown dropdown--left">
      <summary class="dropdown__indicator">
        <i class="icon icon-circle-menu"></i>
      </summary>
      <div class="dropdown__content">
        <ul class="list list--no-link-icon pad-horizontal">
          <li>
            <a class="edit-option" style="cursor: pointer" (click)="onEdit()"
              ><i class="icon i-edit"></i>{{ 'SERVICE_DETAIL_FAILURE_LIST.TABLE.MOREOPTIONS.EDIT' | translate }}
            </a>
          </li>
          <li>
            <a class="delete-option" style="cursor: pointer" (click)="onDelete()">
              <i class="icon i-delete"></i>
              {{ 'SERVICE_DETAIL_FAILURE_LIST.TABLE.MOREOPTIONS.DELETE' | translate }}
            </a>
          </li>
        </ul>
      </div>
    </details>
  </td>
</tr>

<app-affirmation-modal
  (closed)="onAffirmationServiceModalClosed($event)"
  *ngIf="openAffirmationServiceModal"
  [title]="'SERVICE_DETAIL_FAILURE_LIST.AFFIRMATION_MODAL.TITLE' | translate"
  [content]=""
  [confirmContent]="'AFFIRMATION_MODAL.DELETE_YES' | translate"
  [cancelContent]="'AFFIRMATION_MODAL.DELETE_NO' | translate"
></app-affirmation-modal>
