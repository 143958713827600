import { Service } from '../../shared/interfaces/service.interface';
import { RolesService } from '../../shared/services/roles-service.service';
import { Directive, EventEmitter, OnDestroy, Output } from '@angular/core';
import { ItemsClient } from '../../shared/services/items.service';
import { ActivatedRoute, Router } from '@angular/router';
import _ from 'lodash';
import { Subscription } from 'rxjs';

@Directive()
export abstract class ItemsBaseDirective<T> implements OnDestroy {
  @Output() numberChanged = new EventEmitter<number>();
  allItems: T[] = [];
  itemsToDisplay: T[] = [];
  filters = [];
  loadedPercentage;

  queryParamSubscription: Subscription;

  public editedItem: T;

  constructor(
    protected serviceClient: ItemsClient<T>,
    public rolesService: RolesService,
    protected router: Router,
    protected route: ActivatedRoute
  ) {
    this.initFilters();
    this.queryParamSubscription = this.route.queryParams.subscribe((p) => this.setFilters(this.readFilters(p.filters)));

    this.setFilters = this.setFilters.bind(this);
  }

  private initFilters() {
    this.setFilters(this.readFilters(this.route.snapshot.queryParams.filters));
  }

  private setFilters(filters: string[] = []) {
    if (!_.isEqual(this.filters, filters)) {
      this.filters = filters;
      this.onFilterUpdated();
    }
  }

  private readFilters(filtersUri: string): string[] {
    if (!filtersUri) {
      return [];
    }
    const filters = decodeURI(filtersUri);
    return filters.split(',');
  }

  protected refreshItems() {
    this.serviceClient.getItems().subscribe((data: T[]) => {
      this.allItems = data;
      this.itemsToDisplay = this.visibilityFilter(this.allItems);
      this.loadedPercentage = (this.itemsToDisplay.length / this.allItems.length) * 100;
      this.numberChanged.emit(data.length);
    });
  }

  private visibilityFilter = (services: T[]) => services;

  getEditedItemCopy() {
    return JSON.parse(JSON.stringify(this.editedItem));
  }

  setVisibilityFilter(filter: (services: T[]) => T[]) {
    this.visibilityFilter = filter;
    if (this.allItems) {
      this.itemsToDisplay = this.visibilityFilter(this.allItems);
      this.loadedPercentage = (this.itemsToDisplay.length / this.allItems.length) * 100;
    }
  }

  hasAnyMaster(): boolean {
    return this.rolesService.getBrandsWhereMaster().length > 0;
  }

  isMaster = (service: Service) => {
    return this.rolesService.getBrandsWhereMaster().includes(service.brand);
  };

  abstract itemConstructor(): T;

  public createNewItem() {
    this.editedItem = this.itemConstructor();
  }

  editWindowClosed() {
    this.editedItem = null;
  }

  itemCreated(service: T) {
    this.serviceClient.createItem(service).subscribe(() => {
      this.editWindowClosed();
      this.refreshItems();
    });
  }

  itemUpdated(service: T) {
    this.serviceClient.updateItem(service).subscribe(() => {
      this.editWindowClosed();
      this.refreshItems();
    });
  }

  onEdit(service: T) {
    this.editedItem = service;
  }

  onDelete(service: T) {
    this.serviceClient.deleteItem(service).subscribe(() => this.refreshItems());
  }

  loadRemainingData() {
    this.filters = [];
    this.onFilterUpdated();
    this.setVisibilityFilter((services: T[]) => services);
  }

  isAllShown() {
    return this.itemsToDisplay?.length === this.allItems?.length;
  }

  onFilterUpdated() {
    this.storeFilterToUrl().catch((_) => console.error('filter could not be stored'));

    this.setVisibilityFilter((services: T[]) =>
      services.filter((service: T) =>
        this.filters.every((filterTagValue) =>
          this.fieldsToFilterOn().some((parameter) =>
            service[parameter]?.toLowerCase().includes(filterTagValue.toLowerCase())
          )
        )
      )
    );
  }

  abstract fieldsToFilterOn(): string[];

  async storeFilterToUrl() {
    let filters;
    if (this.filters.length) {
      filters = this.filters.join(',');
    } else {
      filters = undefined;
    }

    await this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParams: { filters: filters },
      queryParamsHandling: 'merge',
      replaceUrl: true
    });
  }

  addPreparedFilter(filter: string) {
    if (!this.filters.includes(filter)) {
      this.filters.push(filter);
      this.onFilterUpdated();
    }
  }

  ngOnDestroy(): void {
    this.queryParamSubscription.unsubscribe();
  }
}
