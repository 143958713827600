import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Constants } from '../constants';
import { Observable, of } from 'rxjs';
import { FilterCombination } from '../interfaces/filter-combination.interface';
import { catchError } from 'rxjs/operators';
import { NotificationService } from '../../components/notification/notification.service';

@Injectable({
  providedIn: 'root'
})
export class FilterCombinationsServiceClient {
  constructor(private http: HttpClient, private notifications: NotificationService) {}

  getFilterCombinationsByBrandAndCustomerServiceAndCoreService(
    brand: string,
    customerServiceModId: string,
    coreServiceModId: string
  ): Observable<FilterCombination[]> {
    return this.http
      .get<FilterCombination[]>(
        `${Constants.SERVICE_BY_BRAND_URL}/${brand}/customer-services/${customerServiceModId}/services/${coreServiceModId}/combinations/all`
      )
      .pipe(
        catchError(
          this.handleError('getting filter combinations for core service: ' + brand + ':' + coreServiceModId, null)
        )
      );
  }

  protected handleError<K>(operation = 'operation', result?: K) {
    return (error: HttpErrorResponse): Observable<K> => {
      if (typeof error.error === 'string') {
        this.notifications.error(operation + ' failed: ' + error.status, error.error);
      } else {
        const requestId = error.headers.has('e2ed-requestId')
          ? ' requestId: ' + error.headers.get('e2ed-requestId')
          : '';

        this.notifications.error(
          operation + ' failed, ' + requestId,
          error.status + ' ' + error.statusText + ': ' + error.error.message
        );
      }
      return of(result as K);
    };
  }

  updateFilterCombination(
    brand: string,
    customerServiceModId: string,
    coreServiceModId: string,
    filterCombination: FilterCombination
  ): Observable<void> {
    return this.http
      .patch(
        `${Constants.SERVICE_BY_BRAND_URL}/${brand}/customer-services/${customerServiceModId}/services/${coreServiceModId}/combinations`,
        filterCombination
      )
      .pipe(
        catchError(
          this.handleError('deleting filter combination for core service: ' + brand + ':' + coreServiceModId, null)
        )
      );
  }

  selectOrDeselectAllFilterCombinations(
    brand: string,
    customerServiceModId: string,
    coreServiceModId: string,
    select: boolean
  ): Observable<void> {
    return this.http
      .patch(
        `${Constants.SERVICE_BY_BRAND_URL}/${brand}/customer-services/${customerServiceModId}/services/${coreServiceModId}/combinations/${select}`,
        {}
      )
      .pipe(
        catchError(
          this.handleError('deleting filter combination for core service: ' + brand + ':' + coreServiceModId, null)
        )
      );
  }
}
