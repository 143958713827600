import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Constants } from '../constants';
import { WhitelistedClient } from '../interfaces/whitelisted-client';

@Injectable({
  providedIn: 'root'
})
export class WhitelistedClientsService {
  constructor(private http: HttpClient) {}

  getWhitelistedClients() {
    return this.http.get(Constants.WHITELIST_SERVICE_URL, {
      responseType: 'json'
    });
  }

  addNewWhitelistedClient(client: WhitelistedClient) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.post(
      Constants.WHITELIST_SERVICE_URL,
      JSON.stringify(client),
      httpOptions
    );
  }

  removeWhitelistedClient(client: WhitelistedClient) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: JSON.stringify(client)
    };
    return this.http.delete(Constants.WHITELIST_SERVICE_URL, httpOptions);
  }
}
