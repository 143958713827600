import { Component, OnInit } from '@angular/core';
import { RolesService } from '../../shared/services/roles-service.service';
import { Role } from '../../shared/constants';

import { env } from '../../environment';

import { NgForm } from '@angular/forms';
import { RestrictedWordsService } from '../../shared/services/restricted-words.service';
import {RestrictedWord} from "../../shared/interfaces/RestrictedWord.interface";
@Component({
  selector: 'app-restrictedwords',
  templateUrl: './restricted-words.component.html',
  styleUrls: ['./restricted-words.component.scss']
})
export class RestrictedWordComponent implements OnInit {
  dateTimeFormat: string;
  readonly ADMIN_ROLE: string = Role.ADMIN;
  public initialWords : RestrictedWord[] = [];

  constructor(public restrictedWordsService: RestrictedWordsService, public rolesService: RolesService) {
    this.dateTimeFormat = env.dateTimeFormat;
  }

  ngOnInit() {
    this.getRestrictedWords();
  }

  getRestrictedWords() {
    this.restrictedWordsService.getRestrictedWords().subscribe({
      next: (words: RestrictedWord[]) => {
        this.initialWords = words;
      },
      error: (err: any) => {
        console.error('Failed to load restricted words', err);
      }
    });
  }

  addNewRestrictedWord(myForm: NgForm) {
    const wordToAdd = myForm.controls.word.value;
    this.restrictedWordsService.addNewRestrictedWord(wordToAdd).subscribe((data) => {
      if (this.isWordAlreadyAdded(wordToAdd)) {
        alert(`The word "${wordToAdd}" already exists.`);
        return;
      }
      this.initialWords.push({ word: wordToAdd });
      myForm.resetForm();
    });
  }

  deleteRestrictedWord(word: RestrictedWord) {
    this.restrictedWordsService.deleteRestrictedWord(word).subscribe(()  =>  this.initialWords = this.initialWords.filter(w => w !== word));
  }

  private isWordAlreadyAdded(word: string): boolean {
    return this.initialWords.some(existingWord =>
        existingWord.word.toLowerCase() === word.toLowerCase()
    );
  }
}
