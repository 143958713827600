import { Component, OnInit } from '@angular/core';
import { CoreService, CustomerService } from '../../../../shared/interfaces/service.interface';
import { RolesService } from '../../../../shared/services/roles-service.service';
import {
  CoreServiceClient,
  CustomerServiceClient,
  CustomizedServiceClient
} from '../../../../shared/services/services.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ItemsBaseDirective } from '../../items-base.directive';

@Component({
  selector: 'app-customized-services',
  templateUrl: './customized-services.component.html',
  styleUrls: ['./customized-services.component.scss']
})
export class CustomizedServicesComponent extends ItemsBaseDirective<any> implements OnInit {
  itemConstructor(): CustomerService {
    return {} as CustomerService;
  }
  private coreServices: CoreService[];

  constructor(
    private services: CoreServiceClient,
    private customerServices: CustomerServiceClient,
    private customizedServices: CustomizedServiceClient,
    rolesService: RolesService,
    protected router: Router,
    protected route: ActivatedRoute
  ) {
    super(customizedServices, rolesService, router, route);
  }

  ngOnInit(): void {
    this.services.getItems().subscribe((data) => {
      this.coreServices = data;
    });
    this.refreshItems();
  }

  servicesProvider = () => {
    const brands: string[] = this.rolesService.getBrandsWhereMaster();

    const map = new Map();
    brands.forEach((brand) => {
      const allServicesForBrand = this.coreServices.filter((s: CoreService) => s.brand === brand);

      const generationMap = new Map();
      allServicesForBrand.forEach((service) => {
        const generation = service.generation;
        const list = generationMap.get(generation);
        if (!list) {
          generationMap.set(generation, [service]);
        } else {
          list.push(service);
        }
      });
      map.set(brand, generationMap);
    });

    return map;
  };

  fieldsToFilterOn() {
    return ['name', 'modId', 'brand', 'generation', 'launchDate'];
  }
}
