import { Injectable } from '@angular/core';
import { Role } from '../constants';
import { MappedRoles } from '../models/mapped-roles.model';
import { UserService } from './user-service.service';

@Injectable()
export class RolesService {
  private ROLE_HIERARCHY = [Role.ADMIN, Role.MASTER, Role.BASIC] as const;

  constructor(private userService: UserService) {}

  private getMappedRoles(): MappedRoles {
    return this.userService.getUser().getValue()?.mappedRoles || {};
  }

  getUsername = () => {
    return this.userService.getUser().getValue()?.username;
  };

  public getDominantRole(): Role {
    const mostDominantRoleIndex = Object.values(this.getMappedRoles())
      .map(
        (rolesForBrand) => rolesForBrand.map((role) => this.ROLE_HIERARCHY.findIndex((val) => val === role)).sort()[0]
      )
      .sort()[0];

    return this.ROLE_HIERARCHY[mostDominantRoleIndex];
  }

  public checkIfUserHasPermission(permission: string): boolean {
    for (const entry of Object.values(this.getMappedRoles())) {
      if (entry.includes(permission)) {
        return true;
      }
    }
    return false;
  }

  public isCurrentUser = (userId: string) => {
    return userId === this.getUsername();
  };

  checkIfUserHasAnyOfPermissions(permissions: string[]): boolean {
    for (const roles of Object.values(this.getMappedRoles())) {
      for (const permission of permissions) {
        if (roles.includes(permission)) {
          return true;
        }
      }
    }
    return false;
  }

  getBrandsWhereMaster(): string[] {
    return Object.entries(this.getMappedRoles())
      .filter(([brand, roles]) => roles.includes(Role.MASTER))
      .map((roleMapping) => roleMapping[0]);
  }
}
