export interface Failure {
  name: string;
  statusCode: string;
  useCase: string;
  component: string;
  action: string;
  type?: string;
  description: Localization;
  solution: Solution;
}

export interface Localization {
  [language: string]: string;
}

export interface Solution {
  [role: string]: Localization;
}

export enum FailureType {
  INFO = 'info',
  SUCCESS = 'success',
  ERROR = 'error'
}
