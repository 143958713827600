import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreService, CustomerService, Service } from '../../../shared/interfaces/service.interface';
import { env } from '../../../environment';

@Component({
  selector: 'app-service-details-header',
  templateUrl: './service-details-header.component.html',
  styleUrls: ['./service-details-header.component.scss']
})
export class ServiceDetailsHeaderComponent<T extends Service> implements OnInit {
  @Input() service: T;
  dateFormat: string;

  constructor() {
    this.dateFormat = env.dateFormat;
  }

  ngOnInit(): void {}

  getOwnerNameOrModIds(): string | string[] {
    if (this.checkIfCoreService(this.service)) {
      return ((this.service as unknown) as CoreService).ownerName;
    } else {
      return ((this.service as unknown) as CustomerService).modIds;
    }
  }

  checkIfCoreService(service: any): boolean {
    return 'ownerName' in service;
  }
}
