import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-filter-component',
  templateUrl: './filter-component.component.html',
  styleUrls: ['./filter-component.component.scss']
})
export class FilterComponentComponent {
  @Input() brands: string[];
  @Input() filters: string[];
  @Output() filterUpdated = new EventEmitter<void>();
  @Output() addPreparedFilter = new EventEmitter<string>();

  constructor() {}
}
