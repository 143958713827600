<div *ngIf="rolesService.checkIfUserHasPermission(ADMIN_ROLE); else elseBlock">
  <div class="row row--container mar-top-large">
    <div class="col sm-12">
      <div class="card mar-top-large">
        <div class="card__content">
          <div class="row">
            <div class="col sm-6 pad-top-medium pad-bottom-medium">
              <span class="h4">{{ 'HISTORY.TITLE' | translate }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row row--container">
    <label for="filter" class="col md-9"
      >Filter
      <app-multi-value-input
        name="filter"
        id="filter"
        [items]="filters"
        (itemAdded)="changeFilter()"
        (itemRemoved)="changeFilter()"
      >
      </app-multi-value-input>
    </label>
  </div>

  <div class="row row--container">
    <div class="col sm-12">
      <div class="card mar-top-large">
        <div class="card__content">
          <ul class="list list--hover">
            <li class="horizontal-margins-small col sm-12" *ngFor="let history of itemsToDisplay">
              <div class="col sm-1">{{ history.user }}</div>
              <div class="col sm-2">{{ history.actionType }}</div>
              <div class="col sm-7">{{ history.message }}</div>
              <div class="col sm-2">{{ history.createDate | date: dateTimeFormat }}</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="center mar-bottom-large">
    <p class="center-div">
      {{
        'CREATE_SERVICE_MODAL.NUM_OF_RESULTS'
          | translate
            : {
                x: itemsToDisplay?.length != null ? itemsToDisplay.length : 0,
                y: allItems?.length != null ? allItems.length : 0
              }
      }}
    </p>
    <div class="center-div">
      <progress value="{{ loadedPercentage || 0 }}" max="100"></progress>
    </div>
    <div class="center-div col sm-12">
      <details class="center-div dropdown dropdown--select col sm-2 sm-offset-5">
        <summary class="dropdown__indicator">
          {{ historiesToDisplay }}
        </summary>
        <div class="dropdown__content">
          <ul class="list list--hover list--no-link-icon">
            <li><a (click)="updateNumberOfItemsToDisplay(10)">10</a></li>
            <li><a (click)="updateNumberOfItemsToDisplay(50)">50</a></li>
            <li>
              <a (click)="updateNumberOfItemsToDisplay(-1)">{{ 'HISTORY.SHOW_ALL' | translate }}</a>
            </li>
          </ul>
        </div>
      </details>
    </div>
  </div>
</div>
<ng-template #elseBlock>
  <app-unathorized-access></app-unathorized-access>
</ng-template>
