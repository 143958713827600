import { Component, OnInit, OnDestroy } from '@angular/core';
import { NotificationService } from './notification.service';
import { Subscription } from 'rxjs';
import { Notification, NotificationType } from './notification';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationListComponent implements OnInit, OnDestroy {
  notifications: Notification[] = [];
  private subscription: Subscription;
  types = NotificationType;

  constructor(private notifier: NotificationService) {}

  private addNotification(notification: Notification) {
    this.notifications.push(notification);

    if (notification.timeout !== 0) {
      setTimeout(() => this.close(notification), notification.timeout);
    }
  }

  ngOnInit() {
    this.subscription = this.notifier
      .getObservable()
      .subscribe((notification) => this.addNotification(notification));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  close(notification: Notification) {
    this.notifications = this.notifications.filter(
      (notif) => notif.id !== notification.id
    );
  }
}
