import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../constants';
import { Observable } from 'rxjs';
import { History } from '../interfaces/history.interface';
import { ItemsClient } from './items.service';

@Injectable({
  providedIn: 'root'
})
export class HistoryService implements ItemsClient<History> {
  constructor(private http: HttpClient) {}

  getItems(): Observable<History[]> {
    return this.http
      .get<History[]>(Constants.HISTORY_URL, {
        responseType: 'json'
      })
      .pipe();
  }

  deleteItem(service: History): Observable<any> {
    return undefined;
  }

  createItem(service: History): Observable<unknown> {
    return undefined;
  }

  updateItem(service: History): Observable<unknown> {
    return undefined;
  }
}
