<span [ngSwitch]="this.getDominantRole()" class="avatar-component">
  <img
    *ngSwitchCase="Role.ADMIN"
    src="assets/images/role-avatars/ICON_ADMIN.png"
  />
  <img
    *ngSwitchCase="Role.MASTER"
    src="assets/images/role-avatars/ICON_MASTERDFSS.png"
  />
  <img
    *ngSwitchCase="Role.BASIC"
    src="assets/images/role-avatars/ICON_BASICDFSS.png"
  />
</span>
