<div class="modal failure-detail">
  <div class="modal__wrapper">
    <h1 class="modal__title">{{ 'CREATE_FAILURE_MODAL.TITLE' | translate }}</h1>
    <div class="modal__close" (click)="close()"></div>
    <div class="modal__content">
      <form (ngSubmit)="onSubmit()" [formGroup]="failureForm">
        <ul id="failureFormList" name="failureFormList" class="accordion">
          <li id="basicInfoTab" name="basicInfoTab" [ngClass]="{ 'is-open': competenceActive === 1 }">
            <div class="accordion__title" id="basicInfoTitle" name="basicInfoTitle" (click)="setCompetence(1)">
              {{ 'CREATE_FAILURE_MODAL.BASIC_INFO' | translate }}
            </div>
            <div class="accordion__content">
              <div class="row row--container">
                <div class="col md-8 lg-9">
                  <div class="wrapper wrapper--input">
                    <label for="failureNameInput">{{ 'CREATE_FAILURE_MODAL.FAILURE_NAME' | translate }}</label>
                    <input id="failureNameInput" name="failureNameInput" type="text" formControlName="name" />
                  </div>
                </div>
                <div class="col md-4 lg-3">
                  <div class="wrapper wrapper--input">
                    <label for="failureTypeSelect">
                      {{ 'CREATE_FAILURE_MODAL.FAILURE_TYPE' | translate }}
                      <div class="wrapper wrapper--select">
                        <select id="failureTypeSelect" name="failureTypeSelect" formControlName="type">
                          <option
                            *ngFor="let failureType of FAILURE_TYPES_KEYS; let i = index"
                            type="text"
                            [ngValue]="FailureType[failureType]"
                            [selected]="FailureType[failureType] === failureForm.controls.type.value"
                            [ngClass]="{
                              error: failureType === 'ERROR',
                              success: failureType === 'SUCCESS'
                            }"
                          >
                            {{ failureType }}
                          </option>
                        </select>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
              <div class="row row--container">
                <div class="col sm-6">
                  <div class="wrapper wrapper--input">
                    <label for="statusCodeInput">{{ 'CREATE_FAILURE_MODAL.STATUS_CODE' | translate }}</label>
                    <input id="statusCodeInput" name="statusCodeInput" type="text" formControlName="statusCode" />
                  </div>
                </div>
                <div class="col sm-6">
                  <div class="wrapper wrapper--input">
                    <label for="useCaseInput">{{ 'CREATE_FAILURE_MODAL.USE_CASE' | translate }}</label>
                    <input id="useCaseInput" name="useCaseInput" type="text" formControlName="useCase" />
                  </div>
                </div>
              </div>
              <div class="row row--container">
                <div class="col sm-6">
                  <div class="wrapper wrapper--input">
                    <label for="actionKeyInput">{{ 'CREATE_FAILURE_MODAL.ACTION_NAME' | translate }}</label>
                    <input id="actionKeyInput" name="actionKeyInput" type="text" formControlName="action" />
                  </div>
                </div>
                <div class="col sm-6">
                  <div class="wrapper wrapper--input">
                    <label for="componentInput">{{ 'CREATE_FAILURE_MODAL.COMPONENT' | translate }}</label>
                    <input id="componentInput" name="componentInput" type="text" formControlName="component" />
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li id="descriptionsTab" name="descriptionsTab" [ngClass]="{ 'is-open': competenceActive === 2 }">
            <div class="accordion__title" id="descriptionsTitle" name="descriptionsTitle" (click)="setCompetence(2)">
              {{ 'CREATE_FAILURE_MODAL.DESCRIPTIONS' | translate }}
            </div>
            <div class="accordion__content">
              <app-localization-input
                [markdownElement]="true"
                formControlName="description"
                [languages]="service.supportedLanguages"
              ></app-localization-input>
            </div>
          </li>
          <li id="solutionsTab" name="solutionsTab" [ngClass]="{ 'is-open': competenceActive === 3 }">
            <div class="accordion__title" id="solutionsTitle" name="solutionsTitle" (click)="setCompetence(3)">
              {{ 'CREATE_FAILURE_MODAL.SOLUTIONS' | translate }}
            </div>
            <div class="accordion__content">
              <app-solution-input
                formControlName="solution"
                [languages]="service.supportedLanguages"
              ></app-solution-input>
            </div>
          </li>
        </ul>

        <app-form-error [visible]="failureForm.controls.name.hasError('included') && submitted">
          {{ 'CREATE_FAILURE_MODAL.FORM.INCLUDED' | translate }}
        </app-form-error>
        <br />

        <div class="row">
          <div class="col sm-8"></div>
          <div class="col sm-4">
            <button type="submit" name="acceptButton" class="btn btn--primary btn--expanded save-modal right">
              {{ 'CREATE_FAILURE_MODAL.SUBMIT' | translate }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
