<div class="content content--boxed">
  <div class="row row--container">
    <label for="coreService" class="col lg-4 md-6">
      {{ 'SERVICE_DETAIL_PAGE.CORE_SERVICE_DROPDOWN' | translate }}
      <div class="wrapper wrapper--select col col-sm-8">
        <form [formGroup]="coreServiceForm">
          <select *ngIf="coreServices.length !== 0" id="coreService" name="coreService" formControlName="coreService">
            <option *ngFor="let service of coreServices" [ngValue]="service">{{ service.name }}</option>
          </select>
        </form>
      </div>
    </label>
    <div class="select-all-container lg-3">
      <button (click)="checkAllFilterCombinations(true)" class="btn btn--primary mar-bottom-small">
        {{ 'SERVICE_DETAIL_PAGE.SELECT_ALL_COMBINATIONS' | translate }}
      </button>
      <button (click)="checkAllFilterCombinations(false)" class="btn btn--secondary">
        {{ 'SERVICE_DETAIL_PAGE.DESELECT_ALL_COMBINATIONS' | translate }}
      </button>
    </div>
  </div>

  <div class="row row--container">
    <div
      class="col lg-4 md-6 mar-bottom-large flex flex--equal-size"
      *ngFor="let filterCombination of allFilterCombinations.slice(0, sizeToShow)"
    >
      <div class="card mar-top-large" name="filterCombinationsChecks">
        <div name="card_content" class="card__content pad-medium">
          <input
            type="checkbox"
            [checked]="filterCombination.selected"
            id="checkbox-{{ filterCombination.action }}-{{ filterCombination.useCase }}-{{
              filterCombination.component
            }}"
            (change)="checkFilterCombination(filterCombination)"
          />
          <label
            for="checkbox-{{ filterCombination.action }}-{{ filterCombination.useCase }}-{{
              filterCombination.component
            }}"
          >
            <div class="pad-small">
              <div class="row">{{ 'SERVICE_DETAIL_PAGE.USE_CASE' | translate }} {{ filterCombination.useCase }}</div>
              <div class="row">{{ 'SERVICE_DETAIL_PAGE.ACTION' | translate }} {{ filterCombination.action }}</div>
              <div class="row">{{ 'SERVICE_DETAIL_PAGE.COMPONENT' | translate }} {{ filterCombination.component }}</div>
            </div>
          </label>
          <div class="types status-filters" *ngIf="filterCombination.selected">
            <input
              type="checkbox"
              name="onlySuccess"
              id="only-success-{{ filterCombination.action }}-{{ filterCombination.useCase }}-{{
                filterCombination.component
              }}"
              (change)="statusFilterChange(filterCombination, statusFilter.SUCCESS)"
              [checked]="filterCombination.showingSuccess"
            />
            <label
              for="only-success-{{ filterCombination.action }}-{{ filterCombination.useCase }}-{{
                filterCombination.component
              }}"
              >{{ 'CUSTOMER_SERVICE_DETAIL_PAGE.STATUS_FILTER.SUCCESS' | translate }}</label
            >
            <input
              type="checkbox"
              name="onlyError"
              id="only-error-{{ filterCombination.action }}-{{ filterCombination.useCase }}-{{
                filterCombination.component
              }}"
              (change)="statusFilterChange(filterCombination, statusFilter.ERROR)"
              [checked]="filterCombination.showingError"
            />
            <label
              for="only-error-{{ filterCombination.action }}-{{ filterCombination.useCase }}-{{
                filterCombination.component
              }}"
              >{{ 'CUSTOMER_SERVICE_DETAIL_PAGE.STATUS_FILTER.ERROR' | translate }}</label
            >
            <input
              type="checkbox"
              name="onlyInfo"
              id="only-info-{{ filterCombination.action }}-{{ filterCombination.useCase }}-{{
                filterCombination.component
              }}"
              (change)="statusFilterChange(filterCombination, statusFilter.INFO)"
              [checked]="filterCombination.showingInfo"
            />
            <label
              for="only-info-{{ filterCombination.action }}-{{ filterCombination.useCase }}-{{
                filterCombination.component
              }}"
              >{{ 'CUSTOMER_SERVICE_DETAIL_PAGE.STATUS_FILTER.INFO' | translate }}</label
            >
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="center mar-bottom-large">
    <p class="center-div">
      {{ 'CREATE_SERVICE_MODAL.NUM_OF_RESULTS' | translate: { x: sizeToShow, y: allFilterCombinations?.length } }}
    </p>
    <div class="center-div">
      <progress value="{{ (sizeToShow / allFilterCombinations?.length) * 100 }}" max="100"></progress>
    </div>
    <div class="center-div">
      <button
        class="btn btn--tertiary show-all"
        (click)="loadRemainingData()"
        [disabled]="allFilterCombinations?.length === sizeToShow"
      >
        {{ 'CREATE_SERVICE_MODAL.SHOW_ALL' | translate }}
      </button>
    </div>
  </div>
</div>
