import { Component, forwardRef, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-multi-value-input',
  templateUrl: './multi-value-input.component.html',
  styleUrls: ['./multi-value-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiValueInputComponent),
      multi: true
    }
  ]
})
export class MultiValueInputComponent implements OnInit, ControlValueAccessor {
  @Output() itemAdded = new EventEmitter<string>();
  @Output() itemRemoved = new EventEmitter<string>();
  @Input() items: string[] = [];

  newItem: string;
  constructor() {}

  ngOnInit(): void {}

  writeValue(value: string[]) {
    this.items = value ? value : [];
  }

  propagateChange = (_: any) => {};

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}

  addItem = () => {
    if (this.newItem?.length > 0 && !this.items.includes(this.newItem)) {
      this.items.push(this.newItem);
      this.itemAdded.emit(this.newItem);
      this.newItem = undefined;
      this.propagateChange(this.items);
    }
  };

  removeItem = (ownerId: string) => {
    const index = this.items.indexOf(ownerId);
    if (index > -1) {
      this.items.splice(index, 1);
      this.itemRemoved.emit(ownerId);
      this.propagateChange(this.items);
    }
  };
}
