<div class="row">
  <div class="col lg-4">
    <label>
      <div class="wrapper wrapper--select">
        <select [(ngModel)]="selectedLanguageMod" required name="languageSelect">
          <option *ngFor="let lang of getLanguages()" [ngValue]="lang">
            {{ lang }}
          </option>
        </select>
      </div>
    </label>
  </div>
  <app-markdown-preview class="col lg-12 wrapper" *ngIf="markdownElement; else rawTextBlock" [data]="areaContentMod">
    <textarea rows="4" [(ngModel)]="areaContentMod"></textarea>
  </app-markdown-preview>
  <ng-template #rawTextBlock>
    <textarea rows="4" [(ngModel)]="areaContentMod"></textarea>
  </ng-template>
</div>
