<div class="more-options-menu" id="more-options-{{ elementName }}">
  <i
    class="icon icon-circle-menu"
    (click)="toggle($event)"
    id="more-options-icon-{{ elementName }}"
  ></i>
  <div
    class="more-options-menu-content"
    [class.show-menu]="elementName === selectedElement"
  >
    <div class="card">
      <div class="card__content pad-erase">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
