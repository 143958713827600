<div class="content content--boxed">
  <h4>{{ 'PROFILE.TITLE' | translate }}</h4>
</div>

<div class="profile-page content content--boxed">
  <h5>{{ 'PROFILE.SUMMARY' | translate }}</h5>

  <div class="flex flex--equal-size">
    <div class="box pad-medium">
      <h1 class="subtitle font-size-small">
        {{ 'PROFILE.DETAILS' | translate }}
      </h1>

      <div class="summary-details pad-medium">
        <div class="summary-details-row">
          <span class="summary-details-row-label">{{ 'PROFILE.AVATAR' | translate }}</span>
          :
          <app-avatar></app-avatar>
        </div>
        <div class="summary-details-row">
          <span class="summary-details-row-label">{{ 'PROFILE.USERNAME' | translate }}</span>
          :
          <div>{{ this.getIdClaims().preferred_username }}</div>
        </div>
        <div class="summary-details-row">
          <span class="summary-details-row-label">{{ 'PROFILE.FULL_NAME' | translate }}</span>
          :
          <div>{{ this.getIdClaims().name }}</div>
        </div>
        <div class="summary-details-row">
          <span class="summary-details-row-label">{{ 'PROFILE.CORPORATION' | translate }}</span>
          :
          <div>{{ this.getIdClaims().corporation }}</div>
        </div>
        <div class="summary-details-row">
          <span class="summary-details-row-label">{{ 'PROFILE.DEPARTMENT' | translate }}</span>
          :
          <div>{{ this.getIdClaims().sub_department_number }}</div>
        </div>
        <div class="summary-details-row">
          <span class="summary-details-row-label">{{ 'PROFILE.EMAIL' | translate }}</span>
          :
          <div>{{ this.getIdClaims().email }}</div>
        </div>
        <div class="summary-details-row">
          <span class="summary-details-row-label">{{ 'PROFILE.LAST_LOGIN' | translate }}</span>
          :
          <div>{{ this.getLastLogin() }}</div>
        </div>
      </div>
    </div>

    <div class="box pad-medium">
      <h1 class="subtitle font-size-small">{{ 'PROFILE.GROUPS_ROLES' | translate }}:</h1>
      <ul class="list-style-circle pad-medium">
        <li *ngFor="let role of rawRoles">{{ role }}</li>
      </ul>
    </div>
  </div>
</div>
