import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  Output,
  EventEmitter
} from '@angular/core';

@Component({
  selector: 'app-more-options-menu',
  templateUrl: './more-options-menu.component.html',
  styleUrls: ['./more-options-menu.component.scss']
})
export class MoreOptionsMenuComponent implements OnInit {
  @Input() elementName: string | number;
  @Input() selectedElement: string | number;
  @Output() selectedElementChange = new EventEmitter<string | number>();

  ngOnInit() {}

  toggle(event) {
    event.stopPropagation();
    if (this.selectedElement === this.elementName) {
      this.selectedElementChange.emit(null);
    } else {
      this.selectedElementChange.emit(this.elementName);
    }
  }
}
