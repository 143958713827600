import { CoreService, CustomerService, Generation, Service } from '../../shared/interfaces/service.interface';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Directive, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Localization } from 'src/app/shared/interfaces/failure.interface';
import { arrayNotContainsElement } from '../../shared/validators/array-not-contains-element.directive';
import { CoreServiceClient, CustomerServiceClient } from '../../shared/services/services.service';
import { combineLatest } from 'rxjs';

@Directive()
export abstract class ServiceEditBaseDirective<T extends Service> implements OnInit {
  @Output() closed = new EventEmitter<void>();
  @Output() oncreate = new EventEmitter<T>();
  @Output() onupdate = new EventEmitter<T>();
  public form: UntypedFormGroup;
  private coreServicesArray: CoreService[];
  private customerServicesArray: CustomerService[];
  private mappedServices: Map<string, Service[]>;

  public readonly MOD_ID_KEY = 'modId';
  public readonly NAME_KEY = 'name';
  public readonly DATE_KEY = 'launchDate';
  public readonly BRAND_KEY = 'brand';
  public readonly GENERATION_KEY = 'generation';
  public readonly SUPPORTED_LANGUAGES_KEY = 'supportedLanguages';
  public readonly SERVICE_DESCRIPTION_KEY = 'serviceDescriptions';

  public readonly VIN_REQUIRED = 'isVinRequired';
  public readonly USER_ID_REQUIRED = 'isUserIdRequired';

  public Generation = Generation;
  public GENERATION_KEYS = Object.keys(Generation);

  @Input() initialObject: T;
  public creationMode = true;
  submitted = false;

  constructor(private coreServices: CoreServiceClient, private customerServices: CustomerServiceClient) {}

  ngOnInit() {
    combineLatest([this.coreServices.getItems(), this.customerServices.getItems()]).subscribe((results) => {
      this.coreServicesArray = results[0];
      this.customerServicesArray = results[1];
      this.mappedServices = this.mappingServices();
      this.updateValidators();
    });
  }

  mappingServices = () => {
    return (this.coreServicesArray.map((service) => service as Service) as Service[])
      .concat(this.customerServicesArray.map((service) => service as Service) as Service[])
      .reduce(
        (brandMap, element) => brandMap.set(element.brand, [...(brandMap.get(element.brand) || []), element]),
        new Map()
      );
  };

  close() {
    this.closed.emit();
  }

  onSubmit() {
    this.submitted = true;
    if (this.form.valid) {
      let serviceName = this.form.getRawValue().name;
      const generation = this.form.controls[this.GENERATION_KEY].value.replace(/\s/g, '');
      if (!serviceName.startsWith('MOD:')) {
        if (serviceName.match('^MOD(?:\\s\\d|[0-9])?:?\\s\\S.*')) {
          const nameAfterModId = serviceName.substring(serviceName.indexOf(':') + 1).trim();
          serviceName = generation + ': ' + nameAfterModId;
        } else {
          serviceName = generation + ': ' + serviceName;
        }
      }

      if (this.creationMode) {
        this.oncreate.emit({
          ...this.form.getRawValue(),
          name: serviceName
        });
      } else {
        this.onupdate.emit(this.form.getRawValue());
      }
    }
  }

  updateValidators = () => {
    const selectedBrand = this.mappedServices.get(this.form.get(this.BRAND_KEY).value);
    if (selectedBrand) {
      this.form.controls[this.MOD_ID_KEY].setValidators([
        Validators.required,
        arrayNotContainsElement(selectedBrand.map((item) => item.modId))
      ]);
      this.form.controls[this.NAME_KEY].setValidators([
        Validators.required,
        arrayNotContainsElement(selectedBrand.map((item) => item.name))
      ]);
    } else {
      this.form.controls[this.MOD_ID_KEY].setValidators([Validators.required, arrayNotContainsElement([])]);
      this.form.controls[this.NAME_KEY].setValidators([Validators.required, arrayNotContainsElement([])]);
    }
    this.form.controls[this.MOD_ID_KEY].updateValueAndValidity();
    this.form.controls[this.NAME_KEY].updateValueAndValidity();
  };

  localization(init: Localization, languages: string[]) {
    const obj = {} as Localization;

    languages.forEach((lang) => {
      obj[lang] = init[lang] || '';
    });
    return obj;
  }

  initCommonFields() {
    const initial = this.initialObject;
    this.creationMode = !initial.modId && !initial.brand;

    const initialLanguages = this.creationMode ? ['en'] : initial.supportedLanguages;

    const initialBrand = this.creationMode ? this.brands()[0] : initial.brand;
    const initialDate = this.creationMode ? new Date().toISOString().split('T')[0] : initial.launchDate;

    this.form = new UntypedFormGroup({
      [this.MOD_ID_KEY]: new UntypedFormControl({ value: initial.modId, disabled: !this.creationMode }, [
        Validators.required
      ]),
      [this.NAME_KEY]: new UntypedFormControl({ value: initial.name, disabled: !this.creationMode }, [
        Validators.required
      ]),
      [this.DATE_KEY]: new UntypedFormControl(initialDate, [Validators.required]),
      [this.BRAND_KEY]: new UntypedFormControl({ value: initialBrand, disabled: !this.creationMode }, [
        Validators.required
      ]),
      [this.GENERATION_KEY]: new UntypedFormControl(initial.generation, [Validators.required]),
      [this.SUPPORTED_LANGUAGES_KEY]: new UntypedFormControl(initialLanguages),
      [this.VIN_REQUIRED]: new UntypedFormControl(initial?.isVinRequired || false),
      [this.USER_ID_REQUIRED]: new UntypedFormControl(initial?.isUserIdRequired || false),
      [this.SERVICE_DESCRIPTION_KEY]: new UntypedFormControl(
        this.localization(initial.serviceDescriptions || {}, initialLanguages)
      )
    });
  }

  abstract brands(): string[];
}
