<div class="service-detail-header" *ngIf="service">
  <div class="content content--boxed content--no-elevate pad-top-medium">
    <button class="btn btn--flat pad-erase" appBackButton>
      <i class="icon i-arrow-left"></i>
      {{ 'SERVICE_DETAIL_PAGE.BACKBUTTON' | translate }}
    </button>
    <div class="flex flex--align-items-center flex--row service-header-main">
      <img class="header-e2ed-logo flex__item--flex-none" alt="e2ed-logo" src="/assets/images/e2ed-logo.png" />
      <div class="flex__item flex__item--flex-auto">
        <div class="service-information-label">
          {{ 'SERVICE_DETAIL_PAGE.SERVICE_INFORMATION_LABEL' | translate }}
        </div>
        <h6>{{ service.name }}</h6>
        <div>
          <i class="icon i-car"></i> {{ service.generation }} | <i class="icon i-bookmark"></i> {{ service.modId }}
        </div>
        <div>
          <i class="icon i-car"></i> {{ service.brand }} |
          <i class="icon i-clock"></i>
          {{ service.launchDate | date: dateFormat }}
        </div>
      </div>
      <div class="flex__item flex__item--flex-auto">
        <div>
          {{
            (checkIfCoreService(service)
              ? 'SERVICE_DETAIL_PAGE.RESPONSIBLE_PERSON_LABEL'
              : 'SERVICE_DETAIL_PAGE.CUSTOMER_SERVICE_MODIDS'
            ) | translate
          }}
        </div>
        <div><i class="icon i-user"></i> {{ getOwnerNameOrModIds() }}</div>
      </div>
    </div>
  </div>
</div>
