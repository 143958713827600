<div *ngIf="rolesService.checkIfUserHasPermission(ADMIN_ROLE); else elseBlock">
  <div class="row row--container mar-top-large">
    <div class="col sm-12">
      <div class="card mar-top-large">
        <div class="card__content">
          <div class="row">
            <div class="col sm-6 pad-top-medium pad-bottom-medium" name="RestrictedWordList">
              <span class="h4">{{ 'RESTRICTED_WORDS.TITLE' | translate }}</span>
            </div>
            <div class="col sm-10 cursor-pointer">
              <h2 class="subtitle">{{ 'RESTRICTED_WORDS.DESCRIPTION' | translate }}</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row row--container">
    <div class="col sm-12">
      <div class="card mar-top-large">
        <div class="card__content">
          <table class="table table-striped">
            <thead>
            <tr>
              <th>{{ 'RESTRICTED_WORDS.CURRENT_LIST_TEXT' | translate }}</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of initialWords">
              <td>{{ item.word }}</td>
              <td>
                <button
                  class="btn btn--small bg-danger"
                  name="delete-restricted-word-button"
                  type="submit"
                  id="delete-word-{{ item.word }}"
                  (click)="deleteRestrictedWord(item)"
                >
                  {{ 'RESTRICTED_WORDS.DELETE' | translate }}
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="row row--container">
    <div class="col sm-12">
      <div class="card mar-top-large">
        <div class="card__content">
          <h1 class="h5">{{ 'RESTRICTED_WORDS.ADD_WORDS_SECTION' | translate }}</h1>
          <form #RestrictedWordsForm="ngForm" (ngSubmit)="this.addNewRestrictedWord(RestrictedWordsForm)">
            <div class="row pad-top-medium">
              <div class="col sm-8">
                <input
                  placeholder="{{ 'RESTRICTED_WORDS.PLACEHOLDER_ADD_NEW_WORD' | translate }}"
                  name="word"
                  id="word"
                  class=""
                  type="text"
                  required
                  ngModel
                />
              </div>
              <div>
                <button
                    class="btn btn--primary"
                    name="addWord"
                    id="addWord"
                    type="submit">
                  <span class="icon i-save"></span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>


<ng-template #elseBlock>
  <app-unathorized-access></app-unathorized-access>
</ng-template>
