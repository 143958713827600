import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Constants } from '../constants';
import {RestrictedWord} from "../interfaces/RestrictedWord.interface";

@Injectable({
  providedIn: 'root'
})
export class RestrictedWordsService {
  constructor(private http: HttpClient) {}

  getRestrictedWords() {
    return this.http.get(Constants.RESTRICTED_WORDS, {
      responseType: 'json'
    });
  }

  addNewRestrictedWord(word: string) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    if (word.trim() !== '') {
      return this.http.post(Constants.RESTRICTED_WORDS, JSON.stringify({ word }), httpOptions);
    }
  }

  deleteRestrictedWord(restrictedWord: RestrictedWord) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: JSON.stringify(restrictedWord)
    };
    if (restrictedWord.word.trim() !== '') {
      return this.http.delete(Constants.RESTRICTED_WORDS, httpOptions);
    }
  }
}
