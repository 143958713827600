import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Constants } from 'src/app/shared/constants';
import { Failure, FailureType } from 'src/app/shared/interfaces/failure.interface';
import { CoreService } from '../../../shared/interfaces/service.interface';
import { ExcelService } from '../../../shared/services/excel.service';
import { RolesService } from '../../../shared/services/roles-service.service';
import { ItemsBaseDirective } from '../../services/items-base.directive';
import { FailureService, FailureServiceFactory } from '../../../shared/services/failure.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from '../../notification/notification.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-failures',
  templateUrl: './failures.component.html',
  styleUrls: ['./failures.component.scss']
})
export class FailuresComponent extends ItemsBaseDirective<Failure> implements OnInit {
  readonly ALL_ROLES: string[] = Constants.ALL_ROLES;

  public FailureType = FailureType;
  allFailuresMarked = false;
  markedFailures: { [failureName: string]: Failure } = {};
  openDeletionAffirmation = false;

  @Input() service: CoreService;
  @ViewChild('fileupload') fileupload: ElementRef;

  constructor(
    private serviceFactory: FailureServiceFactory,
    private notifications: NotificationService,
    private excelService: ExcelService,
    rolesService: RolesService,
    router: Router,
    route: ActivatedRoute
  ) {
    super(null, rolesService, router, route);
  }

  ngOnInit(): void {
    this.serviceClient = this.serviceFactory.generateFailureService(this.service);

    if (!this.filters.length) {
      this.setVisibilityFilter((s: Failure[]) => s.slice(0, 10));
    }
    this.refreshItems();
  }

  itemConstructor(): Failure {
    return {} as Failure;
  }

  fieldsToFilterOn(): string[] {
    return ['name', 'action', 'component', 'useCase', 'statusCode'];
  }

  resetMarked() {
    this.allFailuresMarked = false;
    this.markedFailures = {};
  }

  getMarkedFailuresLength() {
    return this.getMarkedFailuresKeys().length;
  }

  getMarkedFailuresKeys() {
    return Object.keys(this.markedFailures);
  }

  toggleAllFailuresMarked() {
    if (this.allFailuresMarked === true) {
      this.unmarkAllFailures();
    } else {
      this.unmarkAllFailures();
      this.allFailuresMarked = true;
      this.itemsToDisplay.forEach((failure) => {
        this.markedFailures[failure.name] = failure;
      });
    }
  }

  editWindowClosed() {
    super.editWindowClosed();
    this.refreshItems();
  }

  toggleFailureMarked(failure: Failure) {
    if (!this.markedFailures[failure.name]) {
      this.markedFailures[failure.name] = failure;
    } else {
      delete this.markedFailures[failure.name];
    }
  }

  async onFilterUpdated() {
    super.onFilterUpdated();

    const filteredMarking = {};
    this.itemsToDisplay.forEach((failure) => {
      if (this.markedFailures[failure.name]) {
        filteredMarking[failure.name] = failure;
      }
    });
    this.markedFailures = filteredMarking;
  }

  // Cancel button
  unmarkAllFailures() {
    this.allFailuresMarked = false;
    this.markedFailures = {};
  }

  askDeleteMarkedFailures() {
    this.openDeletionAffirmation = true;
  }

  onDeletionAffirmationClosed(confirm: boolean) {
    if (confirm) {
      (this.serviceClient as FailureService).deleteFailures(this.getMarkedFailuresKeys()).subscribe(() => {
        this.resetMarked();
        this.refreshItems();
      });
    }
    this.openDeletionAffirmation = false;
  }

  private getSelectedFile() {
    const formData = new FormData();
    formData.append('file', this.fileupload.nativeElement.files.item(0));
    return formData;
  }

  doUploadFile() {
    this.excelService.uploadExcel(this.service.brand, this.service.modId, this.getSelectedFile()).subscribe(
      () => {
        this.resetMarked();
        this.ngOnInit();
        this.notifications.success('ok', 'failure definitions updated');
      },
      (error: HttpErrorResponse) => {
        console.log(error);
        const requestId = error.headers.has('e2ed-requestId')
          ? ' requestId: ' + error.headers.get('e2ed-requestId')
          : '';
        this.notifications.error('ERROR' + requestId, error.error.message);
      }
    );
  }

  findFailureType(value) {
    return Object.keys(FailureType)[Object.values(FailureType).indexOf(value)];
  }

  doDownloadFile() {
    this.excelService.downloadExcel(this.service.brand, this.service.modId);
  }
}
