import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { env } from 'src/app/environment';
import { Newsletter, NewsletterCreationModel } from 'src/app/shared/interfaces/newsletter.interface';

@Component({
  selector: 'app-create-newsletter-modal',
  templateUrl: './create-newsletter-modal.component.html',
  styleUrls: ['./create-newsletter-modal.component.scss']
})
export class CreateNewsletterModalComponent implements OnInit, OnDestroy {
  languages: string[] = env.regions;
  selectedLanguage: string = env.regions[0];
  form: UntypedFormGroup;
  submitted = false;

  @Input() editNewsletterObject: Newsletter;
  @Output() closed = new EventEmitter<NewsletterCreationModel>();

  private mapLangsToFormGroup = () =>
    new UntypedFormBuilder().group(
      this.languages.reduce(
        (prev, curr) => ({
          ...prev,
          [curr]: ''
        }),
        {}
      )
    );

  constructor(private renderer: Renderer2) {
    this.renderer.addClass(document.body, 'modal-open');
    const date = new Date();
    date.setDate(date.getDate() + 1);
    this.form = new UntypedFormBuilder().group({
      expirationDate: formatDate(date, 'yyyy-MM-dd', 'en'),
      title: this.mapLangsToFormGroup(),
      content: this.mapLangsToFormGroup()
    });
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'modal-open');
  }

  ngOnInit(): void {
    if (this.editNewsletterObject) {
      const { id, ...newsletterForm } = this.editNewsletterObject;
      this.form.setValue(newsletterForm);
    }
  }

  confirmForm() {
    this.submitted = true;
    if (this.form.valid) {
      this.closed.emit(this.form.getRawValue());
    }
  }

  earliestDate() {
    const date = new Date();
    date.setDate(date.getDate() + 1);
    return formatDate(date, 'yyyy-MM-dd', 'en');
  }
}
