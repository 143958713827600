import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter, take } from 'rxjs/operators';
import { env } from '../../environment';
import { Role } from '../../shared/constants';
import { User } from '../../shared/models/user.model';
import { RolesService } from '../../shared/services/roles-service.service';
import { UserService } from '../../shared/services/user-service.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  readonly ADMIN_ROLE: string = Role.ADMIN;
  toggledMenu = false;
  regions: Array<string>;
  features: Map<string, boolean>;

  public locale: any;
  public user: User;
  @Output() voted = new EventEmitter<User>();

  constructor(
    private userService: UserService,
    public router: Router,
    private translateService: TranslateService,
    public rolesService: RolesService
  ) {
    this.regions = env.regions;
    this.features = env.features;
  }

  ngOnInit() {
    this.userService.login();
    this.userService
      .getUser()
      .pipe(
        filter((val) => val !== null),
        take(1)
      )
      .subscribe((x) => {
        this.user = x;
      });
    if (localStorage.getItem('theme') === 'dark') {
      document.documentElement.setAttribute('data-theme', 'dark');
      const darkModeSwitch = document.getElementById('dark_mode_switch') as HTMLInputElement;
      darkModeSwitch.checked = true;
    }
  }

  isLoggedIn(): User {
    return this.user;
  }

  logout() {
    this.userService.logout();
  }

  changeLanguage(lang: string) {
    this.translateService.use(lang);
  }

  switchDarkmode(event: any) {
    if (event.srcElement.checked) {
      document.documentElement.setAttribute('data-theme', 'dark');
      localStorage.setItem('theme', 'dark');
    } else {
      document.documentElement.setAttribute('data-theme', 'light');
      localStorage.setItem('theme', 'light');
    }
  }
}
