import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Service } from '../../../shared/interfaces/service.interface';
import { env } from '../../../environment';

@Component({
  selector: 'app-service-item',
  templateUrl: './service-item.component.html',
  styleUrls: ['./service-item.component.scss']
})
export class ServiceItemComponent<T extends Service> implements OnInit {
  @Input() service: T;
  @Input() editable = true;
  dateFormat: string;
  openAffirmationServiceModal = false;

  @Output() delete = new EventEmitter<T>();
  @Output() edit = new EventEmitter<T>();
  @Output() showDetails = new EventEmitter<T>();

  constructor() {
    this.dateFormat = env.dateFormat;
  }

  ngOnInit(): void {}

  onServiceEdit() {
    this.edit.emit(this.service);
  }

  onAffirmationServiceModalClosed(confirmation: boolean) {
    if (confirmation) {
      this.delete.emit(this.service);
    }
    this.openAffirmationServiceModal = false;
  }

  onServiceDelete() {
    this.openAffirmationServiceModal = true;
  }

  onShowDetails() {
    this.showDetails.emit(this.service);
  }
}
