import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  Renderer2
} from '@angular/core';

@Component({
  selector: 'app-affirmation-modal',
  templateUrl: './affirmation-modal.component.html',
  styleUrls: ['./affirmation-modal.component.scss']
})
export class AffirmationServiceModalComponent implements OnDestroy {
  @Input() title: string;
  @Input() content: string;
  @Input() confirmContent: string;
  @Input() cancelContent: string;

  @Output() closed = new EventEmitter<boolean>();

  confirmDialog(bool: boolean) {
    this.closed.emit(bool);
  }

  constructor(private renderer: Renderer2) {
    this.renderer.addClass(document.body, 'modal-open');
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'modal-open');
  }
}
