import { Component, HostListener, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';
import { env } from 'src/app/environment';
import { Role } from 'src/app/shared/constants';
import { Newsletter, NewsletterCreationModel } from 'src/app/shared/interfaces/newsletter.interface';
import { NewsletterService } from 'src/app/shared/services/newsletter.service';
import { RolesService } from 'src/app/shared/services/roles-service.service';

@Component({
  selector: 'app-newsletters-dashboard',
  templateUrl: './newsletters-dashboard.component.html',
  styleUrls: ['./newsletters-dashboard.component.scss']
})
export class NewslettersDashboardComponent implements OnInit {
  ADMIN_ROLE = Role.ADMIN;
  createNewsletterModalOpened = false;
  newsletters: Newsletter[] = [];
  openedOptionsMenuElement = false;
  editNewsletterObject: Newsletter;
  deleteNewsletterObject: Newsletter;
  selectedLanguage: string;
  dateFormat = env.dateFormat;

  constructor(
    public rolesService: RolesService,
    private newsletterService: NewsletterService,
    translateService: TranslateService
  ) {
    this.selectedLanguage = translateService.currentLang;

    translateService.onLangChange.subscribe(({ lang }) => {
      this.selectedLanguage = lang;
    });
  }

  ngOnInit(): void {
    this.refreshNewsletters();
  }

  // close options menu by clicking anywhere
  @HostListener('document:click')
  public onClick() {
    if (this.openedOptionsMenuElement) {
      this.openedOptionsMenuElement = null;
    }
  }

  refreshNewsletters() {
    this.newsletterService.getNewsletters().subscribe((newsletters) => {
      this.newsletters = newsletters;
      this.newsletters.sort((a, b) => Date.parse(a.expirationDate) - Date.parse(b.expirationDate));
    });
  }

  addNewsletter() {
    this.createNewsletterModalOpened = true;
  }

  onNewsletterEditClick(newsletter: Newsletter) {
    this.createNewsletterModalOpened = true;
    this.editNewsletterObject = newsletter;
  }

  onDeleteNewsletterClick(newsletter: Newsletter) {
    this.deleteNewsletterObject = newsletter;
  }

  onDeleteModalClosed(confirm: boolean) {
    if (confirm) {
      this.newsletterService
        .deleteNewsletter(this.deleteNewsletterObject.id)
        .pipe(
          finalize(() => {
            this.deleteNewsletterObject = null;
            this.refreshNewsletters();
          })
        )
        .subscribe();
    } else {
      this.deleteNewsletterObject = null;
    }
  }

  onCreateNewsletterClosed(newsletter: NewsletterCreationModel) {
    if (newsletter) {
      const observable = this.editNewsletterObject
        ? this.newsletterService.updateNewsletter(this.editNewsletterObject.id, newsletter)
        : this.newsletterService.saveNewsletter(newsletter);

      observable
        .pipe(
          finalize(() => {
            this.createNewsletterModalOpened = false;
            this.editNewsletterObject = null;
            this.refreshNewsletters();
          })
        )
        .subscribe();
    } else {
      this.createNewsletterModalOpened = false;
      this.editNewsletterObject = null;
    }
  }
}
