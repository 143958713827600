<label
  ><input type="text" [(ngModel)]="newItem" class="new-item-field" />
  <i class="icon icon-blue-plus cursor-pointer" (click)="addItem()"></i>
</label>
<div>
  <button *ngFor="let item of items" class="btn btn--fab-text btn--secondary item">
    {{ item }}
    <i class="icon i-close" (click)="removeItem(item)"></i>
  </button>
</div>
