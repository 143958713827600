import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-unathorized-access',
  templateUrl: './unathorized-access.component.html',
  styleUrls: ['./unathorized-access.component.scss']
})
export class UnathorizedAccessComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
