/** A hero's name can't match the given regular expression */
import { ValidatorFn } from '@angular/forms';

export function arrayNotContainsElement<T>(
  definedArray: Array<T>
): ValidatorFn {
  return (control) => {
    return definedArray.includes(control.value)
      ? { included: { value: control.value } }
      : null;
  };
}
