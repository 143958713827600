import { Component, OnInit } from '@angular/core';
import { CoreService, CustomerService } from '../../../../shared/interfaces/service.interface';
import { RolesService } from '../../../../shared/services/roles-service.service';
import { CoreServiceClient, CustomerServiceClient } from '../../../../shared/services/services.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ItemsBaseDirective } from '../../items-base.directive';

@Component({
  selector: 'app-customer-services',
  templateUrl: './customer-services.component.html',
  styleUrls: ['./customer-services.component.scss']
})
export class CustomerServicesComponent extends ItemsBaseDirective<any> implements OnInit {
  itemConstructor(): CustomerService {
    return {} as CustomerService;
  }
  private coreServices: CoreService[];

  constructor(
    private services: CoreServiceClient,
    private customerServices: CustomerServiceClient,
    rolesService: RolesService,
    protected router: Router,
    protected route: ActivatedRoute
  ) {
    super(customerServices, rolesService, router, route);
  }

  ngOnInit(): void {
    this.services.getItems().subscribe((data) => {
      this.coreServices = data;
    });
    this.refreshItems();
  }

  servicesProvider = () => {
    const brands: string[] = this.rolesService.getBrandsWhereMaster();

    const map = new Map();
    brands.forEach((brand) => {
      const allServicesForBrand = this.coreServices.filter((s: CoreService) => s.brand === brand);

      const generationMap = new Map();
      allServicesForBrand.forEach((service) => {
        const generation = service.generation;
        const list = generationMap.get(generation);
        if (!list) {
          generationMap.set(generation, [service]);
        } else {
          list.push(service);
        }
      });
      map.set(brand, generationMap);
    });

    return map;
  };

  fieldsToFilterOn() {
    return ['name', 'modId', 'brand', 'generation', 'launchDate'];
  }

  navigateToCustomerServiceDetails(service: CustomerService) {
    if (this.isMaster(service)) {
      this.router.navigate(['/brand/' + service.brand + '/customer-services/' + service.modId]);
    } else {
      console.log('user not allowed to see service content');
    }
  }
}
