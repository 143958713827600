import { Component, Input, OnInit } from '@angular/core';
import { AdditionalParameter, CoreService } from 'src/app/shared/interfaces/service.interface';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { env } from '../../../../environment';
import { Localization, Solution } from '../../../../shared/interfaces/failure.interface';
import { ServiceEditBaseDirective } from '../../service-edit-base.directive';
import { CoreServiceClient, CustomerServiceClient } from '../../../../shared/services/services.service';
import { duplicatedDirective } from '../../../../shared/validators/duplicated.directive';
@Component({
  selector: 'app-core-service-edit',
  templateUrl: './core-service-edit.component.html',
  styleUrls: ['./core-service-edit.component.scss']
})
export class CreateServiceModalComponent extends ServiceEditBaseDirective<CoreService> implements OnInit {
  charactersNumbersPattern = new RegExp('^[A-Za-z0-9]*$');
  public accordionTabActive: number;
  public failureLanguages: string[] = env.failureLanguages;
  public failureRoles: string[] = env.failureRoles;
  public additionalParamForm: UntypedFormGroup;

  public selectedLanguage: string;

  public readonly PRODUCT_OWNER_NAME_KEY = 'ownerName';
  public readonly PRODUCT_OWNER_ID_KEY = 'ownerIds';
  public readonly ADDITIONAL_PARAM_KEY = 'additionalParameters';
  public readonly ADDITIONAL_PARAM_NAME_KEY = 'parameterName';
  public readonly ADDITIONAL_PARAM_MLAAS_KEY = 'mlaasParameterPath';
  public readonly UNDEFINED_SOLUTION_KEY = 'undefinedSolution';
  public readonly UNDEFINED_SOLUTION_TITLE_KEY = 'undefinedSolutionTitle';
  public readonly ABSENT_EVENTS_KEY = 'absentEvents';

  @Input() allServices: CoreService[];
  @Input() masterBrands: string[];

  constructor(private coreServiceClient: CoreServiceClient, private customerServiceClient: CustomerServiceClient) {
    super(coreServiceClient, customerServiceClient);
  }

  ngOnInit() {
    this.initCommonFields();
    super.ngOnInit();
    this.accordionTabActive = 0;
    this.selectedLanguage = this.failureLanguages[0];

    const initial = this.initialObject;
    const languages = this.creationMode ? ['en'] : initial.supportedLanguages;

    this.form.addControl(this.PRODUCT_OWNER_NAME_KEY, new UntypedFormControl(initial.ownerName, [Validators.required]));
    this.form.addControl(this.PRODUCT_OWNER_ID_KEY, new UntypedFormControl(initial.ownerIds));
    this.form.addControl(this.ADDITIONAL_PARAM_KEY, new UntypedFormControl(initial.additionalParameters || []));

    this.additionalParamForm = new UntypedFormGroup({
      [this.ADDITIONAL_PARAM_NAME_KEY]: new UntypedFormControl('', [
        Validators.required,
        duplicatedDirective(() =>
          this.form.get(this.ADDITIONAL_PARAM_KEY).value.map((el: AdditionalParameter) => el.parameterName)
        )
      ]),
      [this.ADDITIONAL_PARAM_MLAAS_KEY]: new UntypedFormControl('')
    });

    this.form.addControl(
      this.UNDEFINED_SOLUTION_TITLE_KEY,
      new UntypedFormControl(this.localization(initial.undefinedSolutionTitle || {}, languages))
    );
    this.form.addControl(
      this.UNDEFINED_SOLUTION_KEY,
      new UntypedFormControl(this.solution(initial.undefinedSolution || {}, languages))
    );
    this.form.addControl(
      this.ABSENT_EVENTS_KEY,
      new UntypedFormControl(this.solution(initial.absentEvents || {}, languages))
    );
  }

  localization(init: Localization, languages: string[]) {
    const obj = {} as Localization;

    languages.forEach((lang) => {
      obj[lang] = init[lang] || '';
    });
    return obj;
  }

  solution(init: Solution, languages: string[]) {
    const obj = {} as Solution;

    this.failureRoles.forEach((role) => {
      obj[role] = this.localization(init[role] || {}, languages);
    });
    return obj;
  }

  brands() {
    return this.masterBrands;
  }

  setAccordionTabActive(tab: number) {
    if (this.accordionTabActive !== tab) {
      this.accordionTabActive = tab;
    } else {
      this.accordionTabActive = -1;
    }
  }

  onChangeOfBrand() {
    this.updateValidators();
  }

  onSupportedLanguageAdded(language: string) {
    const undefinedTitle: Localization = this.form.get(this.UNDEFINED_SOLUTION_TITLE_KEY).value;
    undefinedTitle[language] = '';
    const serviceDescriptions: Localization = this.form.get(this.SERVICE_DESCRIPTION_KEY).value;
    serviceDescriptions[language] = '';

    const undefinedSolution: Solution = this.form.get(this.UNDEFINED_SOLUTION_KEY).value;
    const absentEvents: Solution = this.form.get(this.ABSENT_EVENTS_KEY).value;

    this.failureRoles.forEach((role) => {
      undefinedSolution[role][language] = '';
      absentEvents[role][language] = '';
    });
  }

  onSupportedLanguageRemoved(language: string) {
    const undefinedTitle: Localization = this.form.get(this.UNDEFINED_SOLUTION_TITLE_KEY).value;
    delete undefinedTitle[language];
    const serviceDescriptions: Localization = this.form.get(this.SERVICE_DESCRIPTION_KEY).value;
    delete serviceDescriptions[language];

    const undefinedSolution: Solution = this.form.get(this.UNDEFINED_SOLUTION_KEY).value;
    const absentEvents: Solution = this.form.get(this.ABSENT_EVENTS_KEY).value;
    this.failureRoles.forEach((role) => {
      delete undefinedSolution[role][language];
      delete absentEvents[role][language];
    });
  }

  addAdditionalParameter() {
    if (this.additionalParamForm.valid) {
      const additionalParam = { ...this.additionalParamForm.getRawValue() } as AdditionalParameter;
      this.form.get(this.ADDITIONAL_PARAM_KEY).value.push(additionalParam);

      this.additionalParamForm.reset();
    }
  }

  removeAdditionalParameter(index: number) {
    this.form.get(this.ADDITIONAL_PARAM_KEY).value.splice(index, 1);
  }

  fillAdditionalParamForm(index: number) {
    const additionalParams: [AdditionalParameter] = this.form.get(this.ADDITIONAL_PARAM_KEY).value;

    this.additionalParamForm.get(this.ADDITIONAL_PARAM_NAME_KEY).setValue(additionalParams[index].parameterName);
    this.additionalParamForm.get(this.ADDITIONAL_PARAM_MLAAS_KEY).setValue(additionalParams[index].mlaasParameterPath);
    this.additionalParamForm.markAllAsTouched();
  }
}
