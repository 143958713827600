export const environment = {
  production: false,
  e2edEnv: {
    issuer: 'https://idp-qa.cloud.vwgroup.com/auth/realms/kums-mfa',
    clientId: 'idp-605aaa0d-cbf3-4b80-aa17-cdd675b32797-E2ED-qa',
    regions: ['en', 'de'],
    failureLanguages: ['de', 'en'],
    failureRoles: ['customerCare', 'customer', 'competenceCenter', 'workshop'],
    dateTimeFormat: 'dd-MM-yyyy HH:mm',
    dateFormat: 'dd-MM-yyyy',
    features: {
      whitelisting: true
    },
    roles: {
      VWAG: {
        ADMIN: ['VWAG_E2ED_PRE_ADMIN'],
        MASTER: ['VWAG_E2ED_PRE_MASTERDFSS', 'VWAG_E2ED_PRE_ADMIN'],
        BASIC: ['VWAG_E2ED_PRE_BASICDFSS', 'VWAG_E2ED_PRE_ADMIN']
      },
      SEAT: {
        ADMIN: ['SEAT_E2ED_PRE_ADMIN'],
        MASTER: ['SEAT_E2ED_PRE_MASTERDFSS', 'SEAT_E2ED_PRE_ADMIN'],
        BASIC: ['SEAT_E2ED_PRE_BASICDFSS', 'SEAT_E2ED_PRE_ADMIN']
      },
      PRODUCTION: {
        MASTER: ['VWAG_E2ED_PRE_MASTERDFSS']
      },
      TEST: {
        ADMIN: ['VWAG_E2ED_PRETEST_ADMIN'],
        MASTER: ['VWAG_E2ED_PRETEST_MASTERDFSS', 'VWAG_E2ED_PRETEST_ADMIN'],
        BASIC: ['VWAG_E2ED_PRETEST_BASICDFSS', 'VWAG_E2ED_PRETEST_ADMIN']
      }
    }
  }
};
