<div class="notifications">
  <div *ngFor="let notification of notifications">
    <ng-container
      *ngTemplateOutlet="
        notificationTpl;
        context: { notification: notification }
      "
    ></ng-container>
  </div>
</div>

<ng-template #notificationTpl let-notification="notification ">
  <div
    class="notification notification--tile"
    [ngClass]="{
      'is-successful': notification.type === types.success,
      'is-uberdanger': notification.type === types.error,
      'is-caution': notification.type === types.warning,
      'is-default': notification.type === types.info
    }"
  >
    <div class="notification__content">
      {{ notification.title }}
      <br />
      {{ notification.message }}
    </div>
    <a class="notification__close" (click)="close(notification)"></a>
  </div>
</ng-template>
